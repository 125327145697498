import * as React from "react";
import { useState } from "react";
import { Callout, DirectionalHint, Link } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { Persona } from "@fluentui/react/lib/Persona";
import { useMsal, useAccount } from "@azure/msal-react";
import { graphApiScopes } from "../authConfig";
import { useEffect } from "react";
import {
  calloutAccountDetails,
  calloutAccountInfo,
  calloutEmail,
  calloutMicrosoft,
  calloutName,
  calloutPfp,
  calloutSignOutButton,
  linkSpan,
  meControlCallout,
  meControlIcon,
  profileIconBase,
} from "./Styles";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_MODE, CUSTOMER_MODE, select } from "store/modeSlice";
import { useNavigate } from "react-router-dom";
import { GRAPH_ENDPOINT, MY_ACCOUNT_LINK } from "utils/endpoints";

/**
 * Helper function to get the first initials of the name of the signed-in user
 * @param {*} msalAccount signed-in user account
 * @returns string in the form of "{firstName_initial}{lastName_initial}"
 */
const getUserAccountInitials = (msalAccount) => {
  const nameArr = msalAccount.name ? msalAccount.name.split(" ") : [];
  if (nameArr.length >= 2) {
    return `${nameArr[0].charAt(0)}${nameArr[1].charAt(0)}`;
  } else if (nameArr.length === 1 && nameArr[0].legnth > 0) {
    return `${nameArr[0].charAt(0)}`;
  } else {
    return "NULL";
  }
};

const ProfileIcon = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const userProfileButtonId = useId("profile-button");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // grab global states from redux store
  const availableModes = useSelector((state) => state.mode.modes);
  const currentMode = useSelector((state) => state.mode.currentMode);

  const switchToAdminMode = () => {
    dispatch(select(ADMIN_MODE));
    navigate("/");
  };

  const switchToCustomerMode = () => {
    dispatch(select(CUSTOMER_MODE));
    navigate("/");
  };

  const toggleModeDiv =
    availableModes.length > 1 ? (
      currentMode === CUSTOMER_MODE ? (
        <div className={linkSpan} onClick={() => switchToAdminMode()}>
          Switch to Admin Mode
        </div>
      ) : (
        <div className={linkSpan} onClick={() => switchToCustomerMode()}>
          Switch to Customer Mode
        </div>
      )
    ) : null;

  const signout = () => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  useEffect(() => {
    const getProfilePictureUrl = async () => {
      try {
        const graphToken = await getAuthorizationToken(
          instance,
          graphApiScopes,
          account
        );

        callAuthorizedEndpoint(
          `${GRAPH_ENDPOINT}v1.0/me/photo/$value`,
          graphToken
        )
          .then((response) => response.blob())
          .then((blob) => setProfilePicUrl(window.URL.createObjectURL(blob)))
          .catch((error) => console.error(error));
      } catch (error) {
        console.error("Failed to retrieve graphToken for profile pic");
      }
    };

    getProfilePictureUrl();
  }, [account, instance]);

  const userInitials = getUserAccountInitials(account);

  return (
    <div
      className={profileIconBase}
      id={userProfileButtonId}
      onClick={toggleIsCalloutVisible}
    >
      <div className={meControlIcon}>
        {profilePicUrl ? (
          <Persona
            imageUrl={profilePicUrl}
            imageInitials={userInitials}
            coinSize={28}
          />
        ) : (
          <Persona imageInitials={userInitials} coinSize={28} />
        )}
      </div>
      {isCalloutVisible && (
        <Callout
          role="dialog"
          gapSpace={0}
          target={`#${userProfileButtonId}`}
          onDismiss={toggleIsCalloutVisible}
          isBeakVisible={false}
          directionalHint={DirectionalHint.bottomRightEdge}
          setInitialFocus
        >
          <div className={meControlCallout}>
            <div className={calloutMicrosoft}>Microsoft</div>
            <div className={calloutAccountInfo}>
              <div className={calloutPfp}>
                {profilePicUrl ? (
                  <Persona
                    imageUrl={profilePicUrl}
                    imageInitials={userInitials}
                    coinSize={72}
                  />
                ) : (
                  <Persona imageInitials={userInitials} coinSize={72} />
                )}
              </div>
              <div className={calloutAccountDetails}>
                <div className={calloutName}>{account.name}</div>
                <div className={calloutEmail}>{account.username}</div>
                <Link href={MY_ACCOUNT_LINK}>View Account</Link>
                {toggleModeDiv}
              </div>
            </div>
            <div className={calloutSignOutButton} onClick={signout}>
              Sign out
            </div>
          </div>
        </Callout>
      )}
    </div>
  );
};

export default ProfileIcon;
