import { mergeStyles } from "@fluentui/merge-styles";
var classNames = require("classnames");

const projectHeader = mergeStyles({
  position: "relative",
});

const flexNoShrink = mergeStyles({
  flexShrink: "0",
});

const flexRow = mergeStyles({
  display: "flex",
  flexDirection: "row",
});

const flexGrow = mergeStyles({
  flexGrow: "1",
});

const regionHeader = mergeStyles({
  background: "rgba(255, 255, 255, 1)",
  boxShadow: "rgba(0, 0, 0, .08) 0 1px 0",
  color: "rgba(0, 0, 0, .55)",
  minHeight: "48px",
  overflowX: "hidden",
  zIndex: "100",
});

const meControlDiv = mergeStyles({
  display: "flex",
});

const commandbarItem = mergeStyles({
  [`.${regionHeader} &`]: {
    position: "relative",
  },
  [`.${meControlDiv}&`]: {
    position: "static",
    alignItems: "stretch",
    width: "56px",
  },

  selectors: {
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, .02)",
    },
  },

  alignItems: "center",
  alignSelf: "stretch",
  display: "flex",
  flexShrink: "0",
  outline: "none",
  position: "relative",
});

const scrollHidden = mergeStyles({
  overflow: "hidden",
});

const suiteLogo = mergeStyles({
  color: "rgb(0, 120, 212)",
});

const flexCenter = mergeStyles({
  alignItems: "center",
});

const boltLink = mergeStyles({
  borderRadius: "2px",
  cursor: "pointer",
  textDecoration: "none",
  transition: "color 80ms cubic-bezier(.165,.84,.44,1),background 80ms linear",
});

const suiteImage = mergeStyles({
  [`.${suiteLogo} &`]: {
    fontSize: "1.3125rem",
  },
});

const commandbarIcon = mergeStyles({
  [`.${regionHeader} &`]: {
    width: "48px",
  },
});

const justifyCenter = mergeStyles({
  justifyContent: "center",
});

const fabricIcon = mergeStyles({
  alignSelf: "center",
  display: "inline-flex !important",
});

const brand = mergeStyles({
  [`.${suiteLogo} &`]: {
    width: "212px",
  },
});

const bodyM = mergeStyles({
  fontSize: ".875rem",
});

const fontWeightHeavy = mergeStyles({
  fontWeight: "bold",
});

const boltBreadcrumbWithItems = mergeStyles({
  minWidth: "50px",
});

const cursorPointer = mergeStyles({
  cursor: "pointer",
});

const medium = mergeStyles({
  fontSize: "1rem",
  lineHeight: "1rem",
});

export const meControlIcon = mergeStyles({
  alignItems: "center",
  fontSize: "13px",
  width: "32px",
  margin: "8px",
  display: "flex",
});

export const meControlCallout = mergeStyles({
  [`&>*`]: {
    alignSelf: "center",
    alignItems: "center",
  },
  display: "grid",
  gridTemplateColumns: "auto 1fr auto",
  gridTemplateRows: "48px auto",
  width: "320px",
});

export const calloutCompany = mergeStyles({
  [`.${meControlCallout} &`]: {
    "grid-row": "1/2",
    "grid-column": "1/2",
  },
  display: "block",
  paddingLeft: "14px",
  paddingRight: "14px",
  fontSize: "13px",
  alignItems: "center",
});

export const calloutTruncate = mergeStyles({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

export const calloutAccountInfo = mergeStyles({
  [`.${meControlCallout} &`]: {
    minHeight: "110px",
    width: "100%",
    "grid-row-start": "2",
    "grid-row-end": "3",
    "grid-column-start": "1",
    "grid-column-end": "4",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
});

export const calloutSignout = mergeStyles({
  [`.${meControlCallout} &`]: {
    selectors: {
      ":hover": {
        backgroundColor: "rgba(0, 0, 0, .02)",
      },
    },
    "grid-row-start": "1",
    "grid-row-end": "2",
    "grid-column-start": "3",
    "grid-column-end": "4",
    fontSize: "13px",
    padding: "15px 14px",
  },
});

export const resetStyle = mergeStyles({
  height: "auto",
  minWidth: "auto",
  minHeight: "auto",
  cursor: "pointer",
});

export const calloutAccountDetails = mergeStyles({
  [`.${meControlCallout} &`]: {
    fontSize: "13px",
    flexGrow: "1",
    minWidth: "0",
    width: "0",
    paddingRight: "12px",
  },
  [`&>*`]: {
    padding: "1px",
  },
});

export const calloutName = mergeStyles({
  fontWeight: "700",
  fontSize: "18px",
});

export const calloutEmail = mergeStyles({
  fontSize: "13px",
  marginTop: "3px",
});

export const linkSpan = mergeStyles({
  selectors: {
    ":hover": {
      color: "rgb(0, 69, 120)",
      textDecoration: "underline",
    },
  },
  cursor: "pointer",
  color: "rgb(0, 120, 212)",
});

export const calloutPfp = mergeStyles({
  marginLeft: "16px",
});

export const adminModeSpan = mergeStyles({
  color: "#d92128",
  fontWeight: "600",
  fontSize: "18px",
  letterSpacing: "5px",
  flexGrow: "1",
  textAlign: "center",
  alignSelf: "center",
});

export const notificationBadgeContainer = mergeStyles({
  position: "absolute",
  right: "24px",
  top: "8px",
});

export const notificationBadge = mergeStyles({
  backgroundColor: "rgb(204 16 22)",
  fontFamily: `SegoeUI-Regular-final,Segoe UI,"Segoe UI Web (West European)",Segoe,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,Tahoma,Helvetica,Arial,sans-serif;`,
  fontSize: "10px",
  position: "absolute",
  width: "16px",
  height: "16px",
  lineHeight: "15px",
  borderRadius: "1em",
  boxSizing: "border-box",
  textAlign: "center",
  color: "white",
});

export const wikiCallout = mergeStyles({
  height: "36px",
  width: "150px",
  display: "flex",
  selectors: {
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.06)",
      cursor: "pointer",
    },
  },
});

export const wikiText = mergeStyles({
  width: "100%",
  alignSelf: "center",
  textAlign: "center",
  fontWeight: "bold",
});

export const headerNavBase = classNames(projectHeader, flexNoShrink, flexRow);
export const navBarDiv = classNames(flexRow, flexGrow, regionHeader);
export const logoBase = classNames(
  commandbarItem,
  suiteLogo,
  flexNoShrink,
  flexCenter,
  boltLink
);
export const logoIcon = classNames(
  suiteImage,
  commandbarIcon,
  justifyCenter,
  flexNoShrink,
  fabricIcon
);
export const logoText = classNames(brand, bodyM, fontWeightHeavy);
export const emptySearchBar = classNames(
  flexRow,
  flexGrow,
  scrollHidden,
  boltBreadcrumbWithItems
);
export const iconDiv = classNames(
  commandbarIcon,
  commandbarItem,
  cursorPointer,
  flexRow,
  flexNoShrink,
  justifyCenter,
  flexRow,
  flexCenter
);
export const icon = classNames(flexNoShrink, fabricIcon, medium);
export const profileIconBase = classNames(
  meControlDiv,
  commandbarItem,
  commandbarIcon,
  flexRow,
  flexNoShrink,
  cursorPointer
);
export const calloutMicrosoft = classNames(calloutCompany, calloutTruncate);
export const calloutSignOutButton = classNames(
  resetStyle,
  calloutSignout,
  calloutTruncate
);
