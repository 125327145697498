import {
  CheckboxVisibility,
  DefaultButton,
  ShimmeredDetailsList,
} from "@fluentui/react";
import * as React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  baseDiv,
  headerDiv,
  linkSpan,
  refreshButton,
  tableDiv,
  tableStyling,
  titleFont,
} from "../Styles";
import { getAuthorizationToken } from "utils/AuthorizedFetchCalls";
import { protectedResources } from "authConfig";
import { useAccount, useMsal } from "@azure/msal-react";
import { getSharedBatches } from "store/sharedBatchSlice";
import { useEffect } from "react";

export const SharedBatches = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  // grab global states from redux store
  const sharedBatches = useSelector((state) => state.sharedBatch.list).map(
    (batch) => {
      return {
        ...batch,
        dateModified: new Date(batch.lastModified).toLocaleDateString(),
      };
    }
  );
  const getBatchesStatus = useSelector((state) => state.sharedBatch.status);
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  // Column Settings for the table
  const columns = [
    {
      key: "column1",
      name: "Name",
      fieldName: "name",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      onRender: (item) => {
        return (
          <div
            className={linkSpan}
            onClick={() => navigate(`/sharedbatches/${item.name}`)}
          >
            {item.name}
          </div>
        );
      },
    },
    {
      key: "column2",
      name: "State",
      fieldName: "state",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  /**
   * Function to dispatch the redux action to retrieve the fetching of Shared Batches List
   */
  const getSharedBatchList = useCallback(async () => {
    if (selectedCloud == null) return;

    const accessToken = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    dispatch(getSharedBatches(accessToken));
  }, [account, instance, dispatch, selectedCloud]);

  /**
   * On start, retrieve shared batch list
   */
  useEffect(() => {
    getSharedBatchList();
  }, [getSharedBatchList]);

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>Batches Shared With Me</span>
        <DefaultButton
          text="Refresh"
          onClick={() => getSharedBatchList()}
          className={refreshButton}
        />
      </div>
      <div className={tableDiv}>
        <ShimmeredDetailsList
          items={sharedBatches}
          columns={columns}
          detailsListStyles={{ root: tableStyling }}
          checkboxVisibility={CheckboxVisibility.hidden}
          enableShimmer={getBatchesStatus === "loading"}
        />
      </div>
    </div>
  );
};

export default SharedBatches;
