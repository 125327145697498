import * as React from "react";
import { Callout, Icon, Persona, Spinner, SpinnerSize } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { select } from "store/tentedSlice";
import {
  cloudToggleArrow,
  cloudToggleBase,
  cloudToggleDiv,
  cloudToggleText,
  dropdownIconStyle,
  cloudToggleSelection,
  cloudToggleSelectionBox,
  cloudToggleSelectionText,
  cloudToggleIcon,
  cloudToggleSelectionIcon,
} from "./Styles";

const CloudToggle = () => {
  const dispatch = useDispatch();

  // fields for the cloud toggle callout
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const buttonId = useId("callout-button");

  // grab global states from redux store
  const tentedClouds = useSelector((state) => state.tentedClouds.list);
  const getCloudsStatus = useSelector((state) => state.tentedClouds.status);
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  // Set the icon div
  const loadingSpinner = (
    <Spinner size={SpinnerSize.small} data-testid="loading-spinner" />
  );
  const coin = selectedCloud ? (
    <Persona
      imageInitials={selectedCloud.project.toUpperCase()}
      coinSize={24}
    />
  ) : (
    <></>
  );
  const icon = getCloudsStatus === "loading" ? loadingSpinner : coin;

  // Set the selected cloud name text
  const cloudName = selectedCloud ? selectedCloud.name.toUpperCase() : "";

  /**
   * Function to switch which destination cloud is selected
   * @param {json} cloud the destination cloud object
   */
  const selectCloud = (cloud) => {
    dispatch(select(cloud));
    toggleIsCalloutVisible();
  };

  return (
    <div className={cloudToggleBase}>
      <div className={cloudToggleDiv}>
        <span className={cloudToggleIcon}>{icon}</span>
        <span className={cloudToggleText}>{cloudName}</span>
      </div>
      <div
        id={buttonId}
        onClick={toggleIsCalloutVisible}
        className={cloudToggleArrow}
        data-testid="show-callout"
      >
        <Icon iconName="ChevronDown" className={dropdownIconStyle} />
      </div>
      {isCalloutVisible && (
        <Callout
          role="dialog"
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          isBeakVisible={false}
          setInitialFocus
        >
          {tentedClouds.map((cloud) => {
            return (
              <div
                key={cloud.project}
                onClick={() => selectCloud(cloud)}
                className={cloudToggleSelection}
              >
                <div className={cloudToggleSelectionBox}>
                  <span className={cloudToggleSelectionIcon}>
                    <Persona
                      imageInitials={cloud.project.toUpperCase()}
                      coinSize={24}
                    />
                  </span>
                  <span className={cloudToggleSelectionText}>
                    {cloud.name.toUpperCase()}
                  </span>
                </div>
              </div>
            );
          })}
        </Callout>
      )}
    </div>
  );
};

export default CloudToggle;
