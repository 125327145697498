import { Label } from "@fluentui/react";
import {
  card,
  createLabel,
  createRow,
  createSectionTitle,
  editFlowCard,
  validationLabel,
} from "egressFlows/Styles";

export const dataTypeOptions = [
  { key: "OfficialBuilds", text: "Complex Data - Official Builds" },
  { key: "VHDs", text: "Complex Data - VHDs, Container Images" },
  {
    key: "OfficeDocuments",
    text: "Complex Data - Office Documents, PDF, Image/Video",
  },
  { key: "Scripts", text: "Simple Text - Scripts: PowerShell/Python" },
  {
    key: "JSON",
    text: "Simple Text - JSON/XML/plaintext files (notes & instructions)",
  },
  { key: "Database", text: "Database (SQL, CosmosDB)" },
  { key: "Other", text: "Other" },
];

const FlowDetails = (props) => {
  const { flow } = props;

  return (
    <div className={editFlowCard}>
      <div className={card}>
        <div className={createSectionTitle}>Basics</div>
        <div className={createRow}>
          <div className={createLabel}>Title</div>
          <Label className={validationLabel}>{flow.title}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Dataflow GUID</div>
          <Label className={validationLabel}>{flow.dataflowGuid}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Cloud</div>
          <Label className={validationLabel}>{flow.cloud.toUpperCase()}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>E-mail Address</div>
          <Label className={validationLabel}>{flow.email}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Owner</div>
          <Label className={validationLabel}>{flow.primaryContact}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Contacts</div>
          <Label className={validationLabel}>
            {flow.secondaryContact?.split(";").join(", ")}
          </Label>
        </div>
        <br />
        <div className={createSectionTitle}>Storage Account Details</div>
        {flow.storageTenantId && (
          <div className={createRow}>
            <div className={createLabel}>Tenant ID</div>
            <Label className={validationLabel}>{flow.storageTenantId}</Label>
          </div>
        )}
        <div className={createRow}>
          <div className={createLabel}>Subscription ID</div>
          <Label className={validationLabel}>{flow.subscriptionId}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Resource Group</div>
          <Label className={validationLabel}>{flow.resourceGroup}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Account Name</div>
          <Label className={validationLabel}>{flow.storageAccountName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Type</div>
          <Label className={validationLabel}>{flow.storageContainerType}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Name</div>
          <Label className={validationLabel}>{flow.storageContainerName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Container Region</div>
          <Label className={validationLabel}>
            {flow.storageContainerRegion}
          </Label>
        </div>
        {!flow.storageTenantId && (
          <div className={createRow}>
            <div className={createLabel}>Key Vault URI</div>
            <Label className={validationLabel}>{flow.keyVaultUri}</Label>
          </div>
        )}
        <br />
        <div className={createSectionTitle}>Justification Details</div>
        <div className={createRow}>
          <div className={createLabel}>Service Name</div>
          <Label className={validationLabel}>{flow.serviceName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Service Tree ID</div>
          <Label className={validationLabel}>{flow.serviceTreeId}</Label>
        </div>
      </div>
    </div>
  );
};

export default FlowDetails;
