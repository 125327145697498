import { Icon } from "@fluentui/react";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  displayed,
  navSelectionCondensedDiv,
  navSelectionCondensedRootDiv,
  navSelectionCondensedRootIcon,
  navSelectionCondensedRootIconDiv,
  navSelectionCondensedRootText,
  navSelectionExpandedChildDiv,
  navSelectionExpandedChildIcon,
  navSelectionExpandedChildIconDiv,
  navSelectionExpandedChildText,
  navSelectionExpandedDiv,
  navSelectionExpandedRootDiv,
  navSelectionExpandedRootIcon,
  navSelectionExpandedRootIconDiv,
  navSelectionExpandedRootText,
} from "./Styles";

const NavSelectionGroup = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the entire group should be shown or just the root
  const showGroup = props.links.find((link) => location.pathname === link.path);

  const showGroupDiv = (
    <div className={navSelectionExpandedDiv}>
      <div
        onClick={() => navigate(props.root.path)}
        className={navSelectionExpandedRootDiv}
        data-testid={`${props.root.title}`}
      >
        <span className={navSelectionExpandedRootIconDiv}>
          <Icon
            iconName={props.root.icon}
            className={navSelectionExpandedRootIcon}
          />
        </span>
        <span className={navSelectionExpandedRootText}>{props.root.title}</span>
      </div>

      {props.links.map((link) => {
        const active = link.path === location.pathname ? displayed : "";

        return (
          <div
            key={link.title}
            onClick={() => navigate(link.path)}
            className={`${active} ${navSelectionExpandedChildDiv}`}
            data-testid={`${link.title}`}
          >
            <span className={navSelectionExpandedChildIconDiv}>
              <Icon
                iconName={link.icon}
                className={navSelectionExpandedChildIcon}
              />
            </span>
            <span className={navSelectionExpandedChildText}>{link.title}</span>
          </div>
        );
      })}
    </div>
  );

  const showRootDiv = (
    <div
      onClick={() => navigate(props.root.path)}
      className={navSelectionCondensedDiv}
    >
      <div className={navSelectionCondensedRootDiv}>
        <span className={navSelectionCondensedRootIconDiv}>
          <Icon
            iconName={props.root.icon}
            className={navSelectionCondensedRootIcon}
          />
        </span>
        <span className={navSelectionCondensedRootText}>
          {props.root.title}
        </span>
      </div>
    </div>
  );

  return showGroup ? showGroupDiv : showRootDiv;
};

export default NavSelectionGroup;
