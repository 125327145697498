import { useAccount, useMsal } from "@azure/msal-react";
import { protectedResources } from "authConfig";
import { mergeStyles } from "@fluentui/merge-styles";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClouds } from "store/tentedSlice";
import NavHeader from "./navbar/NavHeader";
import SideNavBar from "./sidenav/SideNavBar";
import { MessageBar } from "@fluentui/react";
import { setShow } from "store/messageBarSlice";
import { getAuthorizationToken } from "utils/AuthorizedFetchCalls";
import { getRoles } from "store/modeSlice";
import { getClusters } from "store/clusterSlice";
import { getNotifications } from "store/notificationSlice";

const layoutStyling = mergeStyles({
  display: "flex",
  flexDirection: "row",
  flexGrow: "1",
  overflow: "hidden",
});

const mainScreen = mergeStyles({
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  overflow: "hidden",
});

const Layout = (props) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const showMessageBar = useSelector((state) => state.messageBar.show);
  const messageBarType = useSelector((state) => state.messageBar.type);
  const messageBarText = useSelector((state) => state.messageBar.message);

  // Since this is the highest level component for any authorized (logged-in) view, all "on-startup" calls/events/functions will be done here
  useEffect(() => {
    const getTentedClouds = async () => {
      const accessToken = await getAuthorizationToken(
        instance,
        protectedResources.backendApi.scopes,
        account
      );
      dispatch(getClouds(accessToken));
    };

    const getAuthorizedRoles = async () => {
      const accessToken = await getAuthorizationToken(
        instance,
        protectedResources.backendApi.scopes,
        account
      );
      dispatch(getRoles(accessToken));
    };

    const getAuthorizedClusters = async () => {
      const accessToken = await getAuthorizationToken(
        instance,
        protectedResources.backendApi.scopes,
        account
      );
      dispatch(getClusters(accessToken));
    };

    const getNotificationList = async () => {
      const accessToken = await getAuthorizationToken(
        instance,
        protectedResources.backendApi.scopes,
        account
      );
      dispatch(getNotifications(accessToken));
    };

    getTentedClouds();
    getAuthorizedRoles();
    getAuthorizedClusters();
    getNotificationList();
  }, [account, dispatch, instance]);

  return (
    <React.Fragment>
      <NavHeader />
      <div className={layoutStyling}>
        <SideNavBar />
        <div className={mainScreen}>
          {showMessageBar && (
            <MessageBar
              messageBarType={messageBarType}
              isMultiline={false}
              onDismiss={() => dispatch(setShow(false))}
            >
              {messageBarText}
            </MessageBar>
          )}
          {props.children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
