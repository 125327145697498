import { useAccount, useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  Dropdown,
  MessageBarType,
  Modal,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { protectedResources } from "authConfig";
import {
  cancelButton,
  modalBodyPadding,
  modalButtons,
  modalContainer,
  modalFooter,
  modalHeader,
} from "ingressFlows/Styles";
import {
  getTenantIdErrorText,
  validGuid,
} from "ingressFlows/createFlowValidation";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";
import {
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminIngressAPI } from "utils/endpoints";

const accessOptions = [
  { key: "oauth", text: "OAuth" },
  { key: "sas", text: "SAS" },
];

const OnboardFlowModal = (props) => {
  const { isModalOpen, hideModal, flow, refresh } = props;
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const [accessType, setAccessType] = useState(accessOptions[0].key);
  const [accountName, setAccountName] = useState("");
  const [containerName, setContainerName] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [kvUri, setKvUri] = useState("");
  const [blocking, setBlocking] = useState(false);

  const buttonEnabled =
    !blocking && accessType === "oauth"
      ? accountName && containerName && tenantId && validGuid(tenantId)
      : accountName && containerName && kvUri;

  const modalBody = (
    <div>
      <TextField
        label="Flow GUID"
        defaultValue={flow.dataflowGuid}
        disabled={true}
      />
      <Dropdown
        label="Access Type"
        selectedKey={accessType}
        onChange={(event, option, index) => setAccessType(option.key)}
        options={accessOptions}
      />
      <TextField
        label="Destination Storage Account Name"
        value={accountName}
        onChange={(event, value) => setAccountName(value)}
      />
      <TextField
        label={`Destination Storage ${flow.storageContainerType} Name`}
        value={containerName}
        onChange={(event, value) => setContainerName(value)}
      />
      {accessType === "sas" ? (
        <TextField
          label="Destination KeyVault Secret URI"
          value={kvUri}
          onChange={(event, value) => setKvUri(value)}
        />
      ) : (
        <TextField
          label="Destination Storage Tenant ID"
          value={tenantId}
          onGetErrorMessage={(value) => getTenantIdErrorText(value)}
          onChange={(event, value) => setTenantId(value)}
        />
      )}
    </div>
  );

  const onboardFlow = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const body = {
      type: accessType,
      flowId: flow.dataflowGuid,
      storageAccountName: accountName,
      storageContainerName: containerName,
      storageTenantId: accessType === "oauth" ? tenantId : null,
      secretUri: accessType === "sas" ? kvUri : null,
    };

    setBlocking(true);

    callAuthorizedEndpointWithBody(
      `${adminIngressAPI}/onboard`,
      token,
      "POST",
      body
    )
      .then(() => {
        dispatch(
          setMessage({
            type: MessageBarType.success,
            message: `TBC Onboard for ${flow.dataflowGuid} succeeded`,
          })
        );
      })
      .catch((res) =>
        res.text().then((error) => {
          dispatch(
            setMessage({
              type: MessageBarType.error,
              message: `Failed Onboard - ${error}`,
            })
          );
          dispatch(setShow(true));
        })
      )
      .finally(() => {
        dispatch(setShow(true));
        setBlocking(false);
        hideModal();
        refresh();
      });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={modalContainer}
    >
      <div className={modalHeader}>Force Onboard Flow</div>
      <div className={modalBodyPadding}>{modalBody}</div>
      <br />
      <div className={modalFooter}>
        <PrimaryButton
          onClick={onboardFlow}
          disabled={!buttonEnabled}
          className={modalButtons}
          data-testid="confirm-button"
        >
          {blocking ? "Onboarding..." : "Onboard"}
        </PrimaryButton>
        <DefaultButton onClick={hideModal} className={cancelButton}>
          Cancel
        </DefaultButton>
      </div>
    </Modal>
  );
};

export default OnboardFlowModal;
