import { useAccount, useMsal } from "@azure/msal-react";
import {
  Icon,
  Label,
  MessageBarType,
  SpinButton,
  TextField,
} from "@fluentui/react";
import { protectedResources } from "authConfig";
import {
  card,
  createInput,
  createLabel,
  createRow,
  createSectionTitle,
  editFlowCard,
  editIconsPadding,
  flowEditDisabledIcons,
  flowEditIcon,
  flowSectionTitleDiv,
  validationLabel,
} from "ingressFlows/Styles";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";
import {
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminIngressAPI } from "utils/endpoints";

export const dataTypeOptions = [
  { key: "OfficialBuilds", text: "Complex Data - Official Builds" },
  { key: "VHDs", text: "Complex Data - VHDs, Container Images" },
  {
    key: "OfficeDocuments",
    text: "Complex Data - Office Documents, PDF, Image/Video",
  },
  { key: "Scripts", text: "Simple Text - Scripts: PowerShell/Python" },
  {
    key: "JSON",
    text: "Simple Text - JSON/XML/plaintext files (notes & instructions)",
  },
  { key: "Database", text: "Database (SQL, CosmosDB)" },
  { key: "Other", text: "Other" },
];

const isEqualsJson = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  //return true when the two json has same length and all the properties has same value key by key
  return (
    keys1.length === keys2.length &&
    Object.keys(obj1).every((key) => obj1[key] === obj2[key])
  );
};

const FlowDetails = (props) => {
  const { flow, setFlow } = props;
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [editMode, setEditMode] = useState(false);
  const [sending, setSending] = useState(false);
  const [modifiedFlow, setModifiedFlow] = useState(flow);

  const isUpdateAllowed = !sending && !isEqualsJson(flow, modifiedFlow);

  const getDiff = (field) =>
    modifiedFlow[field] === flow[field] ? null : modifiedFlow[field];

  const getDiffJson = () => {
    const diffs = {
      Notes: getDiff("notes"),
      SyncPeriod: getDiff("syncPeriod"),
    };

    Object.keys(diffs).forEach((key) => {
      if (diffs[key] === null) {
        delete diffs[key];
      }
    });

    return diffs;
  };

  const updateFlow = async () => {
    const body = getDiffJson();

    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    setSending(true);

    callAuthorizedEndpointWithBody(
      `${adminIngressAPI}/${flow.dataflowGuid}`,
      token,
      "PATCH",
      body
    )
      .then(() => {
        setFlow(modifiedFlow);
        setEditMode(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
        })
      )
      .finally(() => {
        setSending(false);
      });
  };
  const cancelUpdate = () => {
    setEditMode(false);
    setModifiedFlow(flow);
  };

  const editButton = editMode ? (
    <>
      <Icon
        iconName="Accept"
        onClick={() => {
          if (isUpdateAllowed) updateFlow();
        }}
        className={isUpdateAllowed ? flowEditIcon : flowEditDisabledIcons}
        data-testid="update-button"
      />
      <Icon
        iconName="Cancel"
        onClick={() => {
          if (!sending) cancelUpdate();
        }}
        className={`${editIconsPadding} ${flowEditIcon} ${
          sending ? flowEditDisabledIcons : ""
        }`}
        data-testid="cancel-button"
      />
    </>
  ) : (
    <Icon
      iconName="Edit"
      className={flowEditIcon}
      onClick={() => setEditMode(true)}
      data-testid="edit-button"
    />
  );

  return (
    <div className={editFlowCard}>
      <div className={card}>
        <div className={createSectionTitle}>
          <div className={flowSectionTitleDiv}>DRI Details</div>
          {editButton}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Notes</div>
          {editMode ? (
            <TextField
              data-testid="Notes"
              onChange={(event, value) =>
                setModifiedFlow({ ...modifiedFlow, notes: value })
              }
              value={modifiedFlow.notes ?? ""}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.notes}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Cluster</div>
          <Label className={validationLabel}>{flow.cluster}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Sync Period in Minutes</div>
          {editMode && flow.onboarded ? (
            <SpinButton
              value={modifiedFlow.syncPeriod}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  syncPeriod: Number(value),
                })
              }
              min={1}
              step={1}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>
              {flow.onboarded ? flow.syncPeriod : ""}
            </Label>
          )}
        </div>
        <br />
        <div className={createSectionTitle}>
          <div className={flowSectionTitleDiv}>
            Destination Storage Account Details
          </div>
        </div>
        {flow.destinationUseOAuth && (
          <div className={createRow}>
            <div className={createLabel}>Destination Tenant ID</div>
            <Label className={validationLabel}>
              {flow.destinationTenantId}
            </Label>
          </div>
        )}
        <div className={createRow}>
          <div className={createLabel}>Destination Account Name</div>
          <Label className={validationLabel}>{flow.destinationAccount}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Destination Container Name</div>
          <Label className={validationLabel}>{flow.destinationContainer}</Label>
        </div>
        {!flow.destinationUseOAuth && (
          <div className={createRow}>
            <div className={createLabel}>Destination KeyVault URI</div>
            <Label className={validationLabel}>
              {flow.destinationSecretUri}
            </Label>
          </div>
        )}
        <br />
        <div className={createSectionTitle}>Basics</div>
        <div className={createRow}>
          <div className={createLabel}>Title</div>
          <Label className={validationLabel}>{flow.title}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Dataflow GUID</div>
          <Label className={validationLabel}>{flow.dataflowGuid}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Cloud</div>
          <Label className={validationLabel}>{flow.cloud.toUpperCase()}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>E-mail Address</div>
          <Label className={validationLabel}>{flow.email}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Owner</div>
          <Label className={validationLabel}>{flow.primaryContact}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Contacts</div>
          <Label className={validationLabel}>
            {flow.secondaryContact?.split(";").join(", ")}
          </Label>
        </div>
        <br />
        <div className={createSectionTitle}>Storage Account Details</div>
        {flow.storageTenantId && (
          <div className={createRow}>
            <div className={createLabel}>Tenant ID</div>
            <Label className={validationLabel}>{flow.storageTenantId}</Label>
          </div>
        )}
        <div className={createRow}>
          <div className={createLabel}>Subscription ID</div>
          <Label className={validationLabel}>{flow.subscriptionId}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Resource Group</div>
          <Label className={validationLabel}>{flow.resourceGroup}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Account Name</div>
          <Label className={validationLabel}>{flow.storageAccountName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Type</div>
          <Label className={validationLabel}>{flow.storageContainerType}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Name</div>
          <Label className={validationLabel}>{flow.storageContainerName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Container Region</div>
          <Label className={validationLabel}>
            {flow.storageContainerRegion}
          </Label>
        </div>
        {!flow.storageTenantId && (
          <div className={createRow}>
            <div className={createLabel}>Key Vault URI</div>
            <Label className={validationLabel}>{flow.keyVaultUri}</Label>
          </div>
        )}
        <br />
        <div className={createSectionTitle}>Justification Details</div>
        <div className={createRow}>
          <div className={createLabel}>Use Case</div>
          <Label className={validationLabel}>{flow.useCase}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Data Types</div>
          <Label className={validationLabel}>
            {flow.dataTypes
              ?.split(";")
              .map(
                (item) =>
                  dataTypeOptions.find((option) => option.key === item).text
              )
              ?.join("; ")}
          </Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Content from CloudVault</div>
          <Label className={validationLabel}>{flow.cloudVault}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Origin of Data</div>
          <Label className={validationLabel}>{flow.origin}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Destination of Data</div>
          <Label className={validationLabel}>{flow.destination}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>How Will Data be Used</div>
          <Label className={validationLabel}>{flow.dataUse}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Service Name</div>
          <Label className={validationLabel}>{flow.serviceName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Service Tree ID</div>
          <Label className={validationLabel}>{flow.serviceTreeId}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Average Data in a Day (in GB)</div>
          <Label className={validationLabel}>{flow.avgDailyData}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Max Data in a Day (in GB)</div>
          <Label className={validationLabel}>{flow.maxData}</Label>
        </div>
      </div>
    </div>
  );
};

export default FlowDetails;
