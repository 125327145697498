import * as React from "react";
import NavSelectionGroup from "../NavSelectionGroup";

export const egressLinks = [
  {
    title: "My egress flows",
    icon: "CloudSearch",
    path: "/egress",
  },
];

const EgressNavGroup = () => {
  const rootProps = {
    title: "Egress Flows",
    icon: "CloudDownload",
    path: egressLinks[0].path,
  };

  return <NavSelectionGroup root={rootProps} links={egressLinks} />;
};

export default EgressNavGroup;
