import { mergeStyles } from "@fluentui/merge-styles";
var classNames = require("classnames");

const greyPage = mergeStyles({
  backgroundColor: "rgba(248, 248, 248, 1)",
});

const base = mergeStyles({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexGrow: "1",
  height: "auto",
  overflowY: "auto",
});

export const spinnerCentered = mergeStyles({
  position: "relative",
  top: "40%",
});

export const noNotificationsText = mergeStyles({
  top: "45%",
  position: "relative",
  textAlign: "center",
});

export const notificationBase = mergeStyles({
  marginRight: "15%",
  marginLeft: "15%",
  marginTop: "54px",
  backgroundColor: "#FFFFFF",
  border: "1px solid #EFEFEF",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  padding: "12px",
});

export const notifTitle = mergeStyles({
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "1.2125rem",
  lineHeight: "20px",
});

export const borderLine = mergeStyles({
  height: "1px",
  background: "#EDEBE9",
  margin: "10px 0px",
});

export const activityItemStyle = mergeStyles({
  flexGrow: "1",
});

export const activityItemText = mergeStyles({
  fontSize: "14px",
});

export const activityItemIcon = mergeStyles({
  fontSize: "20px",
});

export const bluebg = mergeStyles({
  background: "#e8f3ff",
});

export const notifRow = mergeStyles({
  selectors: {
    ".ms-ActivityItem-activityTypeIcon": {
      alignSelf: "center",
    },
    ".ms-TooltipHost": {
      alignSelf: "center",
    },
  },

  padding: "8px 3px",
  borderRadius: "1px",
  display: "flex",
  flexDirection: "row",
  borderColor: "black",
});

export const activityDeleteIcon = mergeStyles({
  selectors: {
    ":hover": {
      color: "rgba(0, 90, 158, 1)",
      textShadow: "0 0 4px rgba(0, 120, 212, 0.5)",
    },
  },
  marginLeft: "7px",
  cursor: "pointer",
  fontSize: "16px",
  alignSelf: "center",
});

export const tooltipStyle = mergeStyles({
  alignSelf: "center",
});

export const baseDiv = classNames(greyPage, base);
export const unreadNotif = classNames(notifRow, bluebg);
