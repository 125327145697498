import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { callAuthorizedEndpoint } from "utils/AuthorizedFetchCalls";
import { authorizedCloudsAPI } from "utils/endpoints";

export const getClouds = createAsyncThunk(
  "tentedClouds/getClouds",
  async (token, getThunkAPI) => {
    return callAuthorizedEndpoint(authorizedCloudsAPI, token)
      .then((res) => res.json())
      .then((obj) => {
        const cloudList = obj.clouds;
        if (cloudList.length > 0) {
          getThunkAPI.dispatch(select(cloudList[0]));
        }
        return cloudList;
      });
  }
);

export const tentedSlice = createSlice({
  name: "tentedClouds",
  initialState: {
    list: [],
    status: null,
    selected: null,
  },
  reducers: {
    select: (state, action) => {
      state.selected = action.payload;
    },
  },
  extraReducers: {
    [getClouds.pending]: (state, action) => {
      state.status = "loading";
    },
    [getClouds.fulfilled]: (state, { payload }) => {
      state.list = payload;
      state.status = "success";
    },
    [getClouds.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export const { select } = tentedSlice.actions;

export default tentedSlice.reducer;
