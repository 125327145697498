import * as React from "react";
import {
  baseDiv,
  headerDiv,
  linkSpan,
  refreshButton,
  tableStyling,
  titleFont,
} from "../Styles";
import { useNavigate } from "react-router-dom";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { useAccount, useMsal } from "@azure/msal-react";
import { useEffect, useCallback } from "react";
import { protectedResources } from "authConfig";
import { useDispatch, useSelector } from "react-redux";
import { getBatches } from "store/batchSlice";
import { getAuthorizationToken } from "utils/AuthorizedFetchCalls";
import PaginationTable from "paginationTable/Table";

export const Batches = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  // grab global states from redux store
  const batches = useSelector((state) => state.batch.list).map((batch) => {
    return {
      ...batch,
      shortenedName: batch.name.substring(batch.name.indexOf("-") + 1),
      dateModified: new Date(batch.lastModified).toLocaleDateString(),
    };
  });
  const getBatchesStatus = useSelector((state) => state.batch.status);
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  // Column Settings for the Batch Table
  const columns = [
    {
      key: "column1",
      name: "Name",
      fieldName: "shortenedName",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      onRender: (item) => {
        return (
          <div
            className={linkSpan}
            onClick={() => navigate(`/batches/${item.name}`)}
          >
            {item.shortenedName}
          </div>
        );
      },
    },
    {
      key: "column2",
      name: "State",
      fieldName: "state",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  /**
   * Function to dispatch the redux action to retrieve the fetching of Batch List
   */
  const getBatchList = useCallback(async () => {
    if (selectedCloud == null) return;

    const accessToken = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    dispatch(getBatches(accessToken));
  }, [account, instance, dispatch, selectedCloud]);

  /**
   * On start, retrieve batch list
   */
  useEffect(() => {
    getBatchList();
  }, [getBatchList]);

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>My Batches</span>
        <DefaultButton
          text="Refresh"
          onClick={() => getBatchList()}
          className={refreshButton}
        />
      </div>
      <PaginationTable
        items={batches}
        columns={columns}
        tableStyling={tableStyling}
        loading={getBatchesStatus === "loading" || selectedCloud === null}
      />
    </div>
  );
};

export default Batches;
