import { configureStore } from "@reduxjs/toolkit";
import tentedCloudsReducer from "./tentedSlice";
import batchReducer from "./batchSlice";
import sharedBatchReducer from "./sharedBatchSlice";
import messageBarReducer from "./messageBarSlice";
import ingressFlowReducer from "./ingressFlowSlice";
import egressFlowReducer from "./egressFlowSlice";
import modeReducer from "./modeSlice";
import clusterReducer from "./clusterSlice";
import notificationReducer from "./notificationSlice";
import pasteReducer from "./pasteSlice";

const windowIfDefined = typeof window === "undefined" ? null : window;
const enableDevToolsExtension =
  windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__;

export const createStore = () =>
  configureStore({
    reducer: {
      tentedClouds: tentedCloudsReducer,
      batch: batchReducer,
      sharedBatch: sharedBatchReducer,
      messageBar: messageBarReducer,
      ingressFlows: ingressFlowReducer,
      egressFlows: egressFlowReducer,
      mode: modeReducer,
      cluster: clusterReducer,
      notification: notificationReducer,
      paste: pasteReducer,
    },
    devTools: enableDevToolsExtension,
  });

export const store = createStore();
