import { CheckboxVisibility, DetailsList } from "@fluentui/react";
import { pathToFileName } from "./BatchFilesPivot";
import { fileTableDiv, tableStyling } from "../Styles";

const SharedBatchFilesPivot = (props) => {
  const { batch } = props;
  const blobs = batch ? batch.blobs : [];

  // Column Settings for the Table which lists the files in the Batch
  const columns = [
    {
      key: "column1",
      name: "File Name",
      fieldName: "name",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      onRender: (item) => {
        return <div>{pathToFileName(item.name)}</div>;
      },
    },
    {
      key: "column2",
      name: "Size",
      fieldName: "size",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item) => {
        return <div>{`${item.properties.contentLength / 1000} KB`}</div>;
      },
    },
    {
      key: "column3",
      name: "Content Type",
      fieldName: "contentType",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item) => {
        return <div>{item.properties.contentType}</div>;
      },
    },
  ];

  return (
    <div className={fileTableDiv}>
      <DetailsList
        compact={true}
        items={blobs}
        columns={columns}
        checkboxVisibility={CheckboxVisibility.hidden}
        styles={{ root: tableStyling }}
      />
    </div>
  );
};

export default SharedBatchFilesPivot;
