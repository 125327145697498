import { useAccount, useMsal } from "@azure/msal-react";
import { Icon, Label, MessageBarType, TextField } from "@fluentui/react";
import { protectedResources } from "authConfig";
import {
  getStorageAccountErrorText,
  getStorageContainerErrorText,
  getTenantIdErrorText,
  validStorageAccountName,
  validStorageContainerName,
  validTenantId,
} from "ingressFlows/createFlowValidation";
import {
  card,
  createInput,
  createLabel,
  createRow,
  createSectionTitle,
  editFlowCard,
  editIconsPadding,
  flowEditDisabledIcons,
  flowEditIcon,
  flowSectionTitleDiv,
  validationLabel,
} from "ingressFlows/Styles";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage, setShow } from "store/messageBarSlice";
import {
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { ingressFlowAPI } from "utils/endpoints";

export const dataTypeOptions = [
  { key: "OfficialBuilds", text: "Complex Data - Official Builds" },
  { key: "VHDs", text: "Complex Data - VHDs, Container Images" },
  {
    key: "OfficeDocuments",
    text: "Complex Data - Office Documents, PDF, Image/Video",
  },
  { key: "Scripts", text: "Simple Text - Scripts: PowerShell/Python" },
  {
    key: "JSON",
    text: "Simple Text - JSON/XML/plaintext files (notes & instructions)",
  },
  { key: "Database", text: "Database (SQL, CosmosDB)" },
  { key: "Other", text: "Other" },
];

const isEqualsJson = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  //return true when the two json has same length and all the properties has same value key by key
  return (
    keys1.length === keys2.length &&
    Object.keys(obj1).every((key) => obj1[key] === obj2[key])
  );
};

const FlowDetails = (props) => {
  const { flow, setFlow } = props;
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(flow?.onboarded ? false : true);
  const [modifiedFlow, setModifiedFlow] = useState(flow);
  const [sending, setSending] = useState(false);

  const showSasInfo = flow?.onboarded && !flow.destinationUseOAuth;

  const cancelUpdate = () => {
    setEditMode(false);
    setModifiedFlow(flow);
  };

  const isUpdateAllowed =
    !sending &&
    !isEqualsJson(flow, modifiedFlow) &&
    validTenantId(modifiedFlow.destinationTenantId) &&
    validStorageAccountName(modifiedFlow.destinationAccount) &&
    validStorageContainerName(modifiedFlow.destinationContainer);

  const updateFlow = async () => {
    const body = {
      DestinationTenantId: modifiedFlow["destinationTenantId"],
      DestinationAccount: modifiedFlow["destinationAccount"],
      DestinationContainer: modifiedFlow["destinationContainer"],
    };

    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    setSending(true);

    dispatch(
      setMessage({
        type: MessageBarType.info,
        message: "Validating container access...",
      })
    );
    dispatch(setShow(true));

    callAuthorizedEndpointWithBody(
      `${ingressFlowAPI}/${flow.dataflowGuid}`,
      token,
      "PATCH",
      body
    )
      .then(() => {
        setFlow(modifiedFlow);
        setSending(false);
        setEditMode(false);
        dispatch(
          setMessage({
            type: MessageBarType.success,
            message: "Successfully linked destination container!",
          })
        );
        dispatch(setShow(true));
      })
      .catch((response) =>
        response.text().then((text) => {
          setSending(false);
          setEditMode(false);
          dispatch(
            setMessage({
              type: MessageBarType.error,
              message: `Failed to link dest info - ${text}`,
            })
          );
          dispatch(setShow(true));
        })
      );
  };

  const editButton = editMode ? (
    <>
      <Icon
        iconName="Accept"
        onClick={() => {
          if (isUpdateAllowed) updateFlow();
        }}
        className={isUpdateAllowed ? flowEditIcon : flowEditDisabledIcons}
        data-testid="update-button"
      />
      <Icon
        iconName="Cancel"
        onClick={() => {
          if (!sending) cancelUpdate();
        }}
        className={`${editIconsPadding} ${flowEditIcon} ${
          sending ? flowEditDisabledIcons : ""
        }`}
        data-testid="cancel-button"
      />
    </>
  ) : (
    <Icon
      iconName="Edit"
      className={flowEditIcon}
      onClick={() => setEditMode(true)}
      data-testid="edit-button"
    />
  );

  return (
    <div className={editFlowCard}>
      <div className={card}>
        <div className={createSectionTitle}>
          <div className={flowSectionTitleDiv}>
            Destination Storage Account Details
          </div>
          {editButton}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Destination Tenant ID</div>
          {editMode ? (
            <TextField
              data-testid="destTenantId"
              onGetErrorMessage={(value) => getTenantIdErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  destinationTenantId: value.trim(),
                })
              }
              value={modifiedFlow.destinationTenantId ?? ""}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>
              {flow.destinationTenantId}
            </Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Destination Account Name</div>
          {editMode ? (
            <TextField
              data-testid="destAccountName"
              onGetErrorMessage={(value) => getStorageAccountErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  destinationAccount: value.trim(),
                })
              }
              value={modifiedFlow.destinationAccount ?? ""}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>{flow.destinationAccount}</Label>
          )}
        </div>
        <div className={createRow}>
          <div className={createLabel}>Destination Container Name</div>
          {editMode ? (
            <TextField
              data-testid="destContainerName"
              onGetErrorMessage={(value) => getStorageContainerErrorText(value)}
              onChange={(event, value) =>
                setModifiedFlow({
                  ...modifiedFlow,
                  destinationContainer: value.trim(),
                })
              }
              value={modifiedFlow.destinationContainer ?? ""}
              className={createInput}
            />
          ) : (
            <Label className={validationLabel}>
              {flow.destinationContainer}
            </Label>
          )}
        </div>
        {showSasInfo && (
          <div className={createRow}>
            <div className={createLabel}>Destination KeyVault URI</div>
            {editMode ? (
              <TextField
                data-testid="destKeyvaultURI"
                disabled={true}
                value={flow.destinationSecretUri ?? ""}
                className={createInput}
              />
            ) : (
              <Label className={validationLabel}>
                {flow.destinationSecretUri}
              </Label>
            )}
          </div>
        )}
        <br />
        <div className={createSectionTitle}>Basics</div>
        <div className={createRow}>
          <div className={createLabel}>Title</div>
          <Label className={validationLabel}>{flow.title}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Dataflow GUID</div>
          <Label className={validationLabel}>{flow.dataflowGuid}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Cloud</div>
          <Label className={validationLabel}>{flow.cloud.toUpperCase()}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>E-mail Address</div>
          <Label className={validationLabel}>{flow.email}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Owner</div>
          <Label className={validationLabel}>{flow.primaryContact}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Contacts</div>
          <Label className={validationLabel}>
            {flow.secondaryContact?.split(";").join(", ")}
          </Label>
        </div>
        <br />
        <div className={createSectionTitle}>Storage Account Details</div>
        {flow.storageTenantId && (
          <div className={createRow}>
            <div className={createLabel}>Tenant ID</div>
            <Label className={validationLabel}>{flow.storageTenantId}</Label>
          </div>
        )}
        <div className={createRow}>
          <div className={createLabel}>Subscription ID</div>
          <Label className={validationLabel}>{flow.subscriptionId}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Resource Group</div>
          <Label className={validationLabel}>{flow.resourceGroup}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Account Name</div>
          <Label className={validationLabel}>{flow.storageAccountName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Type</div>
          <Label className={validationLabel}>{flow.storageContainerType}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Storage Container Name</div>
          <Label className={validationLabel}>{flow.storageContainerName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Container Region</div>
          <Label className={validationLabel}>
            {flow.storageContainerRegion}
          </Label>
        </div>
        {!flow.storageTenantId && (
          <div className={createRow}>
            <div className={createLabel}>Key Vault URI</div>
            <Label className={validationLabel}>{flow.keyVaultUri}</Label>
          </div>
        )}
        <br />
        <div className={createSectionTitle}>Justification Details</div>
        <div className={createRow}>
          <div className={createLabel}>Use Case</div>
          <Label className={validationLabel}>{flow.useCase}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Data Types</div>
          <Label className={validationLabel}>
            {flow.dataTypes
              ?.split(";")
              .map(
                (item) =>
                  dataTypeOptions.find((option) => option.key === item).text
              )
              ?.join("; ")}
          </Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Content from CloudVault</div>
          <Label className={validationLabel}>{flow.cloudVault}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Origin of Data</div>
          <Label className={validationLabel}>{flow.origin}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Destination of Data</div>
          <Label className={validationLabel}>{flow.destination}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>How Will Data be Used</div>
          <Label className={validationLabel}>{flow.dataUse}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Service Name</div>
          <Label className={validationLabel}>{flow.serviceName}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Service Tree ID</div>
          <Label className={validationLabel}>{flow.serviceTreeId}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Average Data in a Day (in GB)</div>
          <Label className={validationLabel}>{flow.avgDailyData}</Label>
        </div>
        <div className={createRow}>
          <div className={createLabel}>Max Data in a Day (in GB)</div>
          <Label className={validationLabel}>{flow.maxData}</Label>
        </div>
      </div>
    </div>
  );
};

export default FlowDetails;
