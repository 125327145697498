import { MessageBarType } from "@fluentui/react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  callAuthorizedEndpoint,
  callAuthorizedEndpointWithBody,
} from "utils/AuthorizedFetchCalls";
import { adminIngressAPI, ingressFlowAPI } from "utils/endpoints";
import { setShow, setMessage } from "./messageBarSlice";

export const getFlows = createAsyncThunk(
  "ingressFlow/getFlows",
  async (token, getThunkAPI) => {
    return callAuthorizedEndpoint(`${ingressFlowAPI}`, token)
      .then((res) => res.json())
      .then((obj) => {
        const flowList = obj.flows;
        return flowList;
      });
  }
);

export const enableFlowSync = createAsyncThunk(
  "ingressFlow/enableFlowSync",
  async (params, getThunkAPI) => {
    getThunkAPI.dispatch(enableFlow(params.guid));
    return callAuthorizedEndpointWithBody(
      `${ingressFlowAPI}/${params.guid}/enable`,
      params.token,
      "POST",
      {}
    )
      .then(() => true)
      .catch((err) => {
        getThunkAPI.dispatch(
          setMessage({
            type: MessageBarType.error,
            message: "Failed to enable Flow Sync",
          })
        );
        getThunkAPI.dispatch(setShow(true));
        getThunkAPI.dispatch(disableFlow(params.guid));
      });
  }
);

export const disableFlowSync = createAsyncThunk(
  "ingressFlow/disableFlowSync",
  async (params, getThunkAPI) => {
    getThunkAPI.dispatch(disableFlow(params.guid));
    return callAuthorizedEndpointWithBody(
      `${ingressFlowAPI}/${params.guid}/disable`,
      params.token,
      "POST",
      {}
    )
      .then(() => true)
      .catch((err) => {
        getThunkAPI.dispatch(
          setMessage({
            type: MessageBarType.error,
            message: "Failed to disable Flow Sync",
          })
        );
        getThunkAPI.dispatch(setShow(true));
        getThunkAPI.dispatch(enableFlow(params.guid));
      });
  }
);

export const getAdminFlows = createAsyncThunk(
  "ingressFlow/getAdminFlows",
  async (token, getThunkAPI) => {
    return callAuthorizedEndpoint(`${adminIngressAPI}`, token)
      .then((res) => res.json())
      .then((obj) => {
        const flowList = obj.flows;
        return flowList;
      });
  }
);

export const adminEnableFlowSync = createAsyncThunk(
  "ingressFlow/adminEnableFlowSync",
  async (params, getThunkAPI) => {
    getThunkAPI.dispatch(adminEnableFlow(params.guid));
    return callAuthorizedEndpointWithBody(
      `${adminIngressAPI}/${params.guid}/enable`,
      params.token,
      "POST",
      {}
    )
      .then(() => true)
      .catch((err) => {
        getThunkAPI.dispatch(
          setMessage({
            type: MessageBarType.error,
            message: "Failed to enable Flow Sync",
          })
        );
        getThunkAPI.dispatch(setShow(true));
        getThunkAPI.dispatch(adminDisableFlow(params.guid));
      });
  }
);

export const adminDisableFlowSync = createAsyncThunk(
  "ingressFlow/adminDisableFlowSync",
  async (params, getThunkAPI) => {
    getThunkAPI.dispatch(adminDisableFlow(params.guid));
    return callAuthorizedEndpointWithBody(
      `${adminIngressAPI}/${params.guid}/disable`,
      params.token,
      "POST",
      {}
    )
      .then(() => true)
      .catch((err) => {
        getThunkAPI.dispatch(
          setMessage({
            type: MessageBarType.error,
            message: "Failed to disable Flow Sync",
          })
        );
        getThunkAPI.dispatch(setShow(true));
        getThunkAPI.dispatch(adminEnableFlow(params.guid));
      });
  }
);

export const ingressFlowSlice = createSlice({
  name: "ingressFlow",
  initialState: {
    list: [],
    status: null,
    admin_list: [],
    admin_list_status: null,
    approval_list: [],
    approval_list_status: null,
  },
  reducers: {
    enableFlow: (state, action) => {
      state.list = state.list.map((flow) => {
        if (flow.dataflowGuid === action.payload) {
          flow.enabled = true;
        }
        return flow;
      });
    },
    disableFlow: (state, action) => {
      state.list = state.list.map((flow) => {
        if (flow.dataflowGuid === action.payload) {
          flow.enabled = false;
        }
        return flow;
      });
    },
    adminEnableFlow: (state, action) => {
      state.admin_list = state.admin_list.map((flow) => {
        if (flow.dataflowGuid === action.payload) {
          flow.enabled = true;
        }
        return flow;
      });
    },
    adminDisableFlow: (state, action) => {
      state.admin_list = state.admin_list.map((flow) => {
        if (flow.dataflowGuid === action.payload) {
          flow.enabled = false;
        }
        return flow;
      });
    },
  },
  extraReducers: {
    [getFlows.pending]: (state, action) => {
      state.status = "loading";
    },
    [getFlows.fulfilled]: (state, { payload }) => {
      state.list = payload;
      state.status = "success";
    },
    [getFlows.rejected]: (state, action) => {
      state.list = [];
      state.status = "failed";
    },
    [getAdminFlows.pending]: (state, action) => {
      state.admin_list_status = "loading";
    },
    [getAdminFlows.fulfilled]: (state, { payload }) => {
      state.admin_list = payload;
      state.admin_list_status = "success";
    },
    [getAdminFlows.rejected]: (state, action) => {
      state.admin_list = [];
      state.admin_list_status = "failed";
    },
  },
});

export const { enableFlow, disableFlow, adminEnableFlow, adminDisableFlow } =
  ingressFlowSlice.actions;

export default ingressFlowSlice.reducer;
