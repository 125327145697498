import { useAccount, useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBarType,
  PrimaryButton,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { protectedResources } from "authConfig";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setMessage, setShow } from "store/messageBarSlice";
import {
  callAuthorizedEndpoint,
  callAuthorizedEndpointWithBody,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminIngressAPI } from "utils/endpoints";
import LoadingView from "../LoadingView";
import {
  baseDiv,
  cancelButton,
  deleteFlowButton,
  errorTextStyle,
  essentialsText,
  headerDiv,
  pivotPadding,
  titleFont,
} from "../Styles";
import FlowDetails from "./FlowDetails";

export const createGridElements = (keyText, valueText) => (
  <>
    <div className={essentialsText}>{keyText}</div>
    <div className={essentialsText}>:</div>
    <div className={essentialsText}>{valueText}</div>
  </>
);

const AdminIngressFlowDetails = () => {
  const { flowGuid } = useParams();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isLoading, setIsLoading] = useState(true);
  const [errorText, setErrorText] = useState(null);
  const [flow, setFlow] = useState(null);
  const [hideDialog, { toggle: toggleDialog }] = useBoolean(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cachedFlowInfo = useSelector(
    (state) => state.ingressFlows.admin_list
  ).find((flow) => flow.dataflowGuid === flowGuid);
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  const getFlow = useCallback(async () => {
    if (selectedCloud === null) return;

    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    callAuthorizedEndpoint(`${adminIngressAPI}/${flowGuid}`, token)
      .then((response) => response.json())
      .then((response) => {
        setFlow(response.flow);
        setIsLoading(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          setErrorText(text);
          setIsLoading(false);
        })
      );
  }, [account, instance, flowGuid, selectedCloud]);

  useEffect(() => {
    if (cachedFlowInfo) {
      setFlow(cachedFlowInfo);
      setIsLoading(false);
    } else {
      getFlow();
    }
  }, [cachedFlowInfo, getFlow]);

  const deleteDialog = {
    type: DialogType.normal,
    title: "Delete Flow",
    subText: `Are you sure you want to remove this Flow from the Batch Configuration Table?`,
  };

  const deleteFlow = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const apiEndpoint = `${adminIngressAPI}/${flow.dataflowGuid}`;

    callAuthorizedEndpointWithBody(apiEndpoint, token, "DELETE", {})
      .then(() => {
        navigate("/ingress");
      })
      .catch((response) =>
        response.text().then((text) => {
          dispatch(setMessage({ type: MessageBarType.error, message: text }));
          dispatch(setShow(true));
          toggleDialog();
        })
      );
  };

  const content = flow ? (
    <>
      <div className={headerDiv}>
        <span className={titleFont}>{flow.title}</span>
        {flow.onboarded && (
          <PrimaryButton
            text="Delete Flow from TBC"
            onClick={toggleDialog}
            className={deleteFlowButton}
          />
        )}
      </div>
      <div className={pivotPadding}>
        <FlowDetails flow={flow} setFlow={setFlow} />
      </div>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleDialog}
        dialogContentProps={deleteDialog}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={deleteFlow}
            text="Delete"
            className={deleteFlowButton}
            data-testid="delete-button"
          />
          <DefaultButton
            onClick={toggleDialog}
            className={cancelButton}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    </>
  ) : (
    <div className={errorTextStyle}>{errorText}</div>
  );

  return (
    <div className={baseDiv}>
      {isLoading ? <LoadingView label="Fetching Flow details" /> : content}
    </div>
  );
};

export default AdminIngressFlowDetails;
