export const validGuid = (guid) =>
  new RegExp(
    "^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$"
  ).test(guid);

export const validTenantId = (id) => validGuid(id);
export const getTenantIdErrorText = (id) => {
  if (id.trim().length < 1) {
    return "The Tenant ID is required";
  }

  return validTenantId(id) ? "" : "Invalid Tenant ID format";
};

export const validStorageAccountName = (accountName) => {
  return accountName?.trim().length > 0;
};

export const getStorageAccountErrorText = (accountName) => {
  return validStorageAccountName(accountName)
    ? ""
    : "An Azure Storage Account Name is required";
};

export const validStorageContainerName = (containerName) => {
  return containerName?.trim().length > 0;
};

export const getStorageContainerErrorText = (containerName) => {
  return validStorageContainerName(containerName)
    ? ""
    : "The Name of your Storage Table or Blob Container is required";
};
