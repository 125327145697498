import { useAccount, useMsal } from "@azure/msal-react";
import {
  DefaultButton,
  Dropdown,
  MessageBarType,
  PrimaryButton,
} from "@fluentui/react";
import { protectedResources } from "authConfig";
import * as React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAdminFlows } from "store/ingressFlowSlice";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { useBoolean } from "@fluentui/react-hooks";
import {
  baseDiv,
  headerDiv,
  linkSpan,
  refreshButton,
  statusDropdown,
  tableStyling,
  testSasButton,
  titleFont,
} from "ingressFlows/Styles";
import { useState } from "react";
import PaginationTable from "paginationTable/Table";
import { adminIngressAPI } from "utils/endpoints";
import { setMessage, setShow } from "store/messageBarSlice";
import JustificationModal from "./JustificationModal";
import OnboardFlowModal from "./OnboardFlowModal";

export const MODAL_FLOW_DISABLE = "MODAL_FLOW_DISABLE";
export const MODAL_FLOW_ENABLE = "MODAL_FLOW_ENABLE";

export const AdminIngressFlows = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const [testing, setTesting] = useState(false);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [modalType, setModalType] = useState(null);
  const [modalFlow, setModalFlow] = useState(null);
  const [
    isOnboardModalOpen,
    { setTrue: showOnboardModal, setFalse: hideOnboardModal },
  ] = useBoolean(false);
  const [onboardFlow, setOnboardFlow] = useState("");

  // grab global states from redux store
  const flows = useSelector((state) => state.ingressFlows.admin_list);
  const getFlowsStatus = useSelector(
    (state) => state.ingressFlows.admin_list_status
  );
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  const checkAccess = async (flow) => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    setTesting(true);
    dispatch(
      setMessage({
        type: MessageBarType.info,
        message: `Checking access for ${flow.dataflowGuid}`,
      })
    );
    dispatch(setShow(true));

    callAuthorizedEndpoint(
      `${adminIngressAPI}/${flow.dataflowGuid}/checkaccess`,
      token
    )
      .then((res) =>
        res.json().then((response) => {
          if (response.valid) {
            dispatch(
              setMessage({
                type: MessageBarType.success,
                message: `Validation succeeded for ${flow.dataflowGuid}`,
              })
            );
          } else {
            dispatch(
              setMessage({
                type: MessageBarType.warning,
                message: `Validation failed for ${flow.dataflowGuid} - ${response.errorInfo}`,
              })
            );
          }
          dispatch(setShow(true));
        })
      )
      .catch((res) =>
        res.text().then((error) => {
          dispatch(
            setMessage({
              type: MessageBarType.error,
              message: `Backend Error During Validation - ${error}`,
            })
          );
          dispatch(setShow(true));
        })
      )
      .finally(() => setTesting(false));
  };

  const getFlowStatusText = (flow) => {
    if (!flow.finalApprover && !flow.aupForm) {
      return "Needs Lowside Approval";
    }

    if (!flow.onboarded) {
      return (
        <div className={testSasButton}>
          <PrimaryButton
            text="Onboard"
            onClick={() => {
              setOnboardFlow(flow);
              showOnboardModal();
            }}
          />
        </div>
      );
    }

    const options = [
      { key: "Enabled", text: "Enabled" },
      { key: "Disabled", text: "Disabled" },
    ];

    const selectedKey = flow.enabled ? "Enabled" : "Disabled";

    const toggleChange = (toggleKey) => {
      if (toggleKey === selectedKey) return;

      if (toggleKey === "Enabled") {
        setModalType(MODAL_FLOW_ENABLE);
      } else {
        setModalType(MODAL_FLOW_DISABLE);
      }
      setModalFlow(flow);
      showModal();
    };

    return (
      <Dropdown
        selectedKey={selectedKey}
        onChange={(event, option, index) => toggleChange(option.key)}
        options={options}
        className={statusDropdown}
      />
    );
  };

  const columns = [
    {
      key: "column1",
      name: "Dataflow GUID",
      minWidth: 250,
      maxWidth: 250,
      onRender: (item) => {
        return (
          <div
            className={linkSpan}
            onClick={() => navigate(`/ingress/${item.dataflowGuid}`)}
          >
            {item.dataflowGuid}
          </div>
        );
      },
      isResizable: true,
    },
    {
      key: "column2",
      name: "Flow Status",
      fieldName: "state",
      onRender: (item) => {
        return <div>{getFlowStatusText(item)}</div>;
      },
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Check Access",
      onRender: (item) => {
        return (
          <div className={testSasButton}>
            {item.onboarded ? (
              <PrimaryButton
                onClick={() => checkAccess(item)}
                text="Test"
                disabled={testing}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Title",
      fieldName: "title",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "column5",
      name: "Owner",
      fieldName: "primaryContact",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "column6",
      name: "Destination Account Name",
      fieldName: "destinationAccount",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "column7",
      name: "Destination Container Name",
      fieldName: "destinationContainer",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  /**
   * Function to dispatch the redux action to retrieve the fetching of the list of Ingress Flows
   */
  const getFlowList = useCallback(async () => {
    if (selectedCloud == null) return;

    const accessToken = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    dispatch(getAdminFlows(accessToken));
  }, [account, instance, dispatch, selectedCloud]);

  /**
   * On start, retrieve list of flows or when cloud mode got toggled
   */
  useEffect(() => {
    getFlowList();
  }, [getFlowList]);

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>All Ingress Flows</span>
        <DefaultButton
          text="Refresh"
          onClick={() => getFlowList()}
          className={refreshButton}
        />
      </div>
      <PaginationTable
        items={flows}
        columns={columns}
        tableStyling={tableStyling}
        loading={getFlowsStatus === "loading" || selectedCloud === null}
      />
      {isModalOpen && (
        <JustificationModal
          {...{ isModalOpen, hideModal, modalType, modalFlow }}
        />
      )}
      {isOnboardModalOpen && (
        <OnboardFlowModal
          isModalOpen={isOnboardModalOpen}
          hideModal={hideOnboardModal}
          flow={onboardFlow}
          refresh={getFlowList}
        />
      )}
    </div>
  );
};
export default AdminIngressFlows;
