import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { callAuthorizedEndpoint } from "utils/AuthorizedFetchCalls";
import { authorizedRolesAPI } from "utils/endpoints";

// Defined constants for what view/mode is currently being rendered
export const CUSTOMER_MODE = 0;
export const ADMIN_MODE = 1;

export const getRoles = createAsyncThunk(
  "mode/getRoles",
  async (token, getThunkAPI) => {
    return callAuthorizedEndpoint(authorizedRolesAPI, token)
      .then((res) => res.json())
      .then((roles) => {
        const allowedModes = [CUSTOMER_MODE];
        if (roles.dri || roles.approver || roles.verifier) {
          allowedModes.push(ADMIN_MODE);
        }
        return { roles: roles, modes: allowedModes };
      });
  }
);

export const modeSlice = createSlice({
  name: "mode",
  initialState: {
    currentMode: CUSTOMER_MODE,
    modes: [],
    roles: {
      dri: false,
      approver: false,
      verifier: false,
    },
    status: null,
  },
  reducers: {
    select: (state, action) => {
      state.currentMode = action.payload;
    },
  },
  extraReducers: {
    [getRoles.pending]: (state, action) => {
      state.status = "loading";
    },
    [getRoles.fulfilled]: (state, { payload }) => {
      state.modes = payload.modes;
      state.roles = payload.roles;
      state.status = "success";
    },
    [getRoles.rejected]: (state, action) => {
      state.modes = [];
      state.roles = {
        dri: false,
        approver: false,
        verifier: false,
      };
      state.status = "failed";
    },
  },
});

export const { select } = modeSlice.actions;

export default modeSlice.reducer;
