import { mergeStyles } from "@fluentui/merge-styles";
var classNames = require("classnames");

const greyPage = mergeStyles({
  backgroundColor: "rgba(248, 248, 248, 1)",
});

const base = mergeStyles({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  flexGrow: "1",
  height: "auto",
  overflowY: "auto",
});

export const headerTitle = mergeStyles({
  paddingTop: "24px",
  paddingLeft: "20px",
  paddingRight: "20px",
  marginLeft: "12px",
  marginRight: "12px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
});

export const titleFont = mergeStyles({
  fontWeight: "400",
  fontSize: "36px",
});

export const bodyText = mergeStyles({
  marginTop: "1rem",
  fontSize: "16px",
  fontWeight: "400",
  alignSelf: "center",
  maxWidth: "50vw",
  textAlign: "center",
});

export const navRow = mergeStyles({
  marginTop: "5rem",
  maxWidth: "1300px",
  alignSelf: "center",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
});

export const card = mergeStyles({
  selectors: {
    ":hover": {
      transition: "all .2s ease-in-out",
      boxShadow: "0 5px 10px rgb(0 0 0 / 14%)",
    },
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  textAlign: "center",
  backgroundColor: "#fff",
  borderRadius: "6px",
  boxShadow: "0 2px 5px rgb(0 0 0 / 14%)",
  color: "#4c4c51",
  position: "relative",
  border: "1px solid transparent",
  padding: "24px",
  cursor: "pointer",
  maxWidth: "225px",
  margin: "10px 24px",
  height: "275px",
});

export const cardIcon = mergeStyles({
  fontSize: "36px",
  color: "#0062ad",
});

export const cardDescription = mergeStyles({
  fontSize: "16px",
  margin: "12px 0",
});

export const cardFooter = mergeStyles({
  [`.${card}:hover &`]: {
    textDecoration: "underline",
  },
  fontSize: "14px",
  fontWeight: "600",
  margin: "0 0 9px",
  color: "#0062ad",
});

export const baseDiv = classNames(greyPage, base);
