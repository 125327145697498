import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { callAuthorizedEndpoint } from "utils/AuthorizedFetchCalls";
import { adminEgressAPI, egressFlowAPI } from "utils/endpoints";

export const getFlows = createAsyncThunk(
  "egressFlow/getFlows",
  async (token, getThunkAPI) => {
    return callAuthorizedEndpoint(`${egressFlowAPI}`, token)
      .then((res) => res.json())
      .then((obj) => {
        const flowList = obj.flows;
        return flowList;
      });
  }
);

export const getAdminFlows = createAsyncThunk(
  "egressFlow/getAdminFlows",
  async (token, getThunkAPI) => {
    return callAuthorizedEndpoint(`${adminEgressAPI}`, token)
      .then((res) => res.json())
      .then((obj) => {
        const flowList = obj.flows;
        return flowList;
      });
  }
);

export const egressFlowSlice = createSlice({
  name: "egressFlow",
  initialState: {
    list: [],
    status: null,
    admin_list: [],
    admin_list_status: null,
    verify_list: [],
    verify_list_status: null,
    approval_list: [],
    approval_list_status: null,
  },
  extraReducers: {
    [getFlows.pending]: (state, action) => {
      state.status = "loading";
    },
    [getFlows.fulfilled]: (state, { payload }) => {
      state.list = payload;
      state.status = "success";
    },
    [getFlows.rejected]: (state, action) => {
      state.list = [];
      state.status = "failed";
    },
    [getAdminFlows.pending]: (state, action) => {
      state.admin_list_status = "loading";
    },
    [getAdminFlows.fulfilled]: (state, { payload }) => {
      state.admin_list = payload;
      state.admin_list_status = "success";
    },
    [getAdminFlows.rejected]: (state, action) => {
      state.admin_list = [];
      state.admin_list_status = "failed";
    },
  },
});

export default egressFlowSlice.reducer;
