import { MessageBarType } from "@fluentui/react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  callAuthorizedEndpoint,
  callAuthorizedEndpointWithBody,
} from "utils/AuthorizedFetchCalls";
import { notificationAPI } from "utils/endpoints";
import { setMessage, setShow } from "./messageBarSlice";

export const getNotifications = createAsyncThunk(
  "notification/getNotifications",
  async (token, getThunkAPI) => {
    return callAuthorizedEndpoint(notificationAPI, token)
      .then((res) => res.json())
      .then((obj) => {
        const notifications = obj.notifications;
        return notifications;
      });
  }
);

export const deleteNotification = createAsyncThunk(
  "notification/deleteNotification",
  async (params, getThunkAPI) => {
    const deletedNotif = getThunkAPI
      .getState()
      .notification.list.find((notification) => notification.id === params.id);
    getThunkAPI.dispatch(removeNotification(params.id));
    return callAuthorizedEndpointWithBody(
      `${notificationAPI}/${params.id}`,
      params.token,
      "DELETE",
      {}
    )
      .then((res) => true)
      .catch((err) => {
        getThunkAPI.dispatch(
          setMessage({
            type: MessageBarType.error,
            message: "Failed to delete Notification",
          })
        );
        getThunkAPI.dispatch(setShow(true));
        getThunkAPI.dispatch(addNotification(deletedNotif));
      });
  }
);

export const markNotificationRead = createAsyncThunk(
  "notification/markNotificationRead",
  async (params, getThunkAPI) => {
    getThunkAPI.dispatch(readNotification(params.id));
    return callAuthorizedEndpointWithBody(
      `${notificationAPI}/${params.id}`,
      params.token,
      "PATCH",
      {}
    )
      .then((res) => true)
      .catch((err) => {
        getThunkAPI.dispatch(
          setMessage({
            type: MessageBarType.error,
            message: "Failed to update Notification",
          })
        );
        getThunkAPI.dispatch(setShow(true));
        getThunkAPI.dispatch(unreadNotification(params.id));
      });
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    list: [],
    status: null,
  },
  reducers: {
    removeNotification: (state, action) => {
      state.list = state.list
        .filter((notification) => notification.id !== action.payload)
        .map((notification) => notification);
    },
    addNotification: (state, action) => {
      state.list = state.list
        .map((notification) => notification)
        .push(action.payload);
    },
    readNotification: (state, action) => {
      state.list = state.list.map((notification) => ({
        ...notification,
        isRead: notification.id === action.payload ? true : notification.isRead,
      }));
    },
    unreadNotification: (state, action) => {
      state.list = state.list.map((notification) => ({
        ...notification,
        isRead:
          notification.id === action.payload ? false : notification.isRead,
      }));
    },
  },
  extraReducers: {
    [getNotifications.pending]: (state, action) => {
      state.status = "loading";
    },
    [getNotifications.fulfilled]: (state, { payload }) => {
      state.list = payload;
      state.status = "success";
    },
    [getNotifications.rejected]: (state, action) => {
      state.list = [];
      state.status = "failed";
    },
  },
});

export const {
  removeNotification,
  addNotification,
  readNotification,
  unreadNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
