import * as React from "react";
import { Route, Routes } from "react-router-dom";
import {
  AuthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import { mergeStyles } from "@fluentui/merge-styles";

import Layout from "./Layout";
import Notifications from "notifications/Notifications";
import Batches from "batches/customer/Batches";
import SharedBatches from "batches/customer/SharedBatches";
import SharedBatchDetail from "batches/customer/SharedBatchDetails";
import BatchDetails from "batches/customer/BatchDetails";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ADMIN_MODE, CUSTOMER_MODE } from "store/modeSlice";
import CustomerHome from "pages/CustomerHome";
import AdminHome from "pages/AdminHome";
import IngressFlows from "ingressFlows/customer/IngressFlows";
import IngressFlowDetails from "ingressFlows/customer/IngressFlowDetails";
import EgressFlows from "egressFlows/customer/EgressFlows";
import EgressFlowDetails from "egressFlows/customer/EgressFlowDetails";
import AdminIngressFlows from "ingressFlows/admin/AdminIngressFlows";
import AdminIngressFlowDetails from "ingressFlows/admin/AdminIngressFlowDetails";
import AdminEgressFlows from "egressFlows/admin/AdminEgressFlows";
import AdminEgressFlowDetails from "egressFlows/admin/AdminEgressFlowDetails";
import Pastes from "pastebin/customer/Pastes";
import PasteDetails from "pastebin/customer/PasteDetails";
import SharedPastes from "pastebin/customer/SharedPastes";
import * as monaco from "monaco-editor";
import { loader } from "@monaco-editor/react";
import ConvertFlowAccess from "ingressFlows/admin/ConvertFlowAccess";

const rootStyle = mergeStyles({
  [`& a`]: {
    color: "rgb(0, 120, 212)",
  },
  selectors: {
    ".btn-primary": {
      color: "#fff",
      backgrounColor: "#1b6ec2",
      borderColor: "#1861ac",
    },
  },

  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  overflow: "hidden",
  fontSize: "14px",
  height: "100%",
});

loader.config({ monaco });

const App = () => {
  useMsalAuthentication("redirect");

  const currentMode = useSelector((state) => state.mode.currentMode);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
  });

  return (
    <div className={rootStyle}>
      <AuthenticatedTemplate>
        <Layout>
          <Routes>
            {currentMode === CUSTOMER_MODE && (
              <>
                <Route path="/" element={<CustomerHome />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="batches">
                  <Route index element={<Batches />} />
                  <Route path=":batchName" element={<BatchDetails />} />
                </Route>
                <Route path="sharedbatches">
                  <Route index element={<SharedBatches />} />
                  <Route path=":batchName" element={<SharedBatchDetail />} />
                </Route>
                <Route path="pastes">
                  <Route index element={<Pastes />} />
                  <Route path=":pasteUrl" element={<PasteDetails />} />
                </Route>
                <Route path="sharedpastes" element={<SharedPastes />} />
                <Route path="ingress">
                  <Route index element={<IngressFlows />} />
                  <Route path=":flowGuid" element={<IngressFlowDetails />} />
                </Route>
                <Route path="egress">
                  <Route index element={<EgressFlows />} />
                  <Route path=":flowGuid" element={<EgressFlowDetails />} />
                </Route>
              </>
            )}
            {currentMode === ADMIN_MODE && (
              <>
                <Route path="/" element={<AdminHome />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="ingress">
                  <Route index element={<AdminIngressFlows />} />
                  <Route
                    path=":flowGuid"
                    element={<AdminIngressFlowDetails />}
                  />
                </Route>
                <Route
                  path="convertflowaccess"
                  element={<ConvertFlowAccess />}
                />
                <Route path="egress">
                  <Route index element={<AdminEgressFlows />} />
                  <Route
                    path=":flowGuid"
                    element={<AdminEgressFlowDetails />}
                  />
                </Route>
              </>
            )}
          </Routes>
        </Layout>
      </AuthenticatedTemplate>
    </div>
  );
};

export default App;
