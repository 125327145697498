import * as React from "react";
import ProfileIcon from "./ProfileIcon";
import { Icon } from "@fluentui/react/lib/Icon";
import {
  adminModeSpan,
  emptySearchBar,
  headerNavBase,
  icon,
  iconDiv,
  logoBase,
  logoIcon,
  logoText,
  navBarDiv,
  notificationBadge,
  notificationBadgeContainer,
} from "./Styles";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_MODE, CUSTOMER_MODE, select } from "store/modeSlice";
import { useNavigate } from "react-router-dom";

const NavHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const availableModes = useSelector((state) => state.mode.modes);
  const currentMode = useSelector((state) => state.mode.currentMode);
  const notificationList = useSelector((state) => state.notification.list);
  const unread = notificationList.filter((notification) => {
    return !notification.isRead && !notification.isDeleted;
  }).length;

  const switchToAdminMode = () => {
    dispatch(select(ADMIN_MODE));
    navigate("/");
  };

  const switchToCustomerMode = () => {
    dispatch(select(CUSTOMER_MODE));
    navigate("/");
  };

  const adminToggleIcon = () => {
    if (availableModes.length < 2) {
      return null;
    }

    if (currentMode === CUSTOMER_MODE) {
      return (
        <div className={iconDiv} onClick={switchToAdminMode}>
          <Icon iconName="CRMServices" className={icon} />
        </div>
      );
    } else if (currentMode === ADMIN_MODE) {
      return (
        <div className={iconDiv} onClick={switchToCustomerMode}>
          <Icon iconName="Contact" className={icon} />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={headerNavBase}>
      <div className={navBarDiv}>
        <a aria-label="CTS Portal home page" className={logoBase} href="/">
          <Icon iconName="AzureLogo" className={logoIcon} />
          <span className={logoText}>CTS Portal</span>
        </a>
        {currentMode === CUSTOMER_MODE ? (
          <div className={emptySearchBar} />
        ) : (
          <div className={adminModeSpan}>ADMIN MODE</div>
        )}
        {adminToggleIcon()}
        <div onClick={() => navigate("/notifications")} className={iconDiv}>
          <Icon iconName="Ringer" className={icon} />
          {unread > 0 && (
            <div className={notificationBadgeContainer}>
              <span className={notificationBadge}>{unread}</span>
            </div>
          )}
        </div>
        <ProfileIcon />
      </div>
    </div>
  );
};

export default NavHeader;
