import * as React from "react";
import {
  baseDiv,
  headerDiv,
  batchTitleFont,
  errorTextStyle,
  headerButtonPadding,
  batchDetailsPivot,
} from "../Styles";
import { useEffect, useState } from "react";
import { protectedResources } from "../../authConfig";
import { useAccount, useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { batchesAPI } from "utils/endpoints";
import { DefaultButton } from "@fluentui/react/lib/Button";
import BatchFilesPivot from "./BatchFilesPivot";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setShow, setMessage } from "store/messageBarSlice";
import LoadingView from "../LoadingView";
import { downloadToFiles, downloadToZip } from "batches/batchDownloader";

export const BatchDetails = () => {
  const { batchName } = useParams();
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [batch, setBatch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorText, setErrorText] = useState(null);

  const dispatchFunc = (type, text) => {
    dispatch(
      setMessage({
        type: type,
        message: text,
      })
    );
    dispatch(setShow(true));
  };

  const getFileSAS = async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    const sasPromises = batch.blobs.map((blob) => {
      const fileName = encodeURIComponent(blob.name);
      return callAuthorizedEndpoint(
        `${batchesAPI}/sas?file=${fileName}`,
        token
      );
    });

    return Promise.all(sasPromises).then((responses) =>
      Promise.all(responses.map((r) => r.json()))
    );
  };

  const downloadFiles = async () => {
    const filesArr = await getFileSAS();
    downloadToFiles(filesArr);
  };

  const downloadZip = async () => {
    const filesArr = await getFileSAS();
    downloadToZip(filesArr, batchName, dispatchFunc);
  };

  const downloadMenuProps = {
    items: [
      {
        key: "downloadFiles",
        text: "Download Files",
        onClick: (ev, props) => downloadFiles(),
      },
      {
        key: "downloadZip",
        text: "Download as Zip",
        onClick: (ev, props) => downloadZip(),
      },
    ],
    directionalHintFixed: true,
  };

  const fetchBatch = useCallback(async () => {
    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    callAuthorizedEndpoint(`${batchesAPI}/${batchName.trim()}`, token)
      .then((response) => response.json())
      .then((batchObj) => {
        batchObj.allBlobs = batchObj.blobs;
        batchObj.blobs = batchObj.blobs
          ? batchObj.blobs.filter(
              (blob) => !blob.name.endsWith(".teleportal.sha512")
            )
          : [];
        setBatch(batchObj);
        setLoading(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          setErrorText(text);
          setLoading(false);
        })
      );
  }, [account, batchName, instance]);

  useEffect(() => {
    fetchBatch();
  }, [fetchBatch]);

  const batchView = batch ? (
    <>
      <div className={headerDiv}>
        <span className={batchTitleFont}>
          {batch.name.substring(batch.name.indexOf("-") + 1)}
        </span>
        <DefaultButton
          text="Download Batch"
          menuProps={downloadMenuProps}
          className={headerButtonPadding}
        />
      </div>
      <div className={batchDetailsPivot}>
        <BatchFilesPivot batch={batch} />
      </div>
    </>
  ) : (
    <div className={errorTextStyle}>{errorText}</div>
  );

  return <div className={baseDiv}>{loading ? <LoadingView /> : batchView}</div>;
};

export default BatchDetails;
