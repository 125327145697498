import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { callAuthorizedEndpoint } from "utils/AuthorizedFetchCalls";
import { pastesAPI, sharedPastesAPI } from "utils/endpoints";

export const getPastes = createAsyncThunk(
  "paste/getPastes",
  async (token, getThunkAPI) => {
    return callAuthorizedEndpoint(`${pastesAPI}`, token)
      .then((res) => res.json())
      .then((obj) => {
        const pasteList = obj.pastes;
        return pasteList;
      });
  }
);

export const getSharedPastes = createAsyncThunk(
  "paste/getSharedPastes",
  async (token, getThunkAPI) => {
    return callAuthorizedEndpoint(`${sharedPastesAPI}`, token)
      .then((res) => res.json())
      .then((obj) => {
        const pasteList = obj.pastes;
        return pasteList;
      });
  }
);

export const pasteSlice = createSlice({
  name: "paste",
  initialState: {
    list: [],
    status: null,
    shared_list: [],
    shared_status: null,
  },
  extraReducers: {
    [getPastes.pending]: (state, action) => {
      state.status = "loading";
    },
    [getPastes.fulfilled]: (state, { payload }) => {
      state.list = payload;
      state.status = "success";
    },
    [getPastes.rejected]: (state, action) => {
      state.status = "failed";
    },
    [getSharedPastes.pending]: (state, action) => {
      state.shared_status = "loading";
    },
    [getSharedPastes.fulfilled]: (state, { payload }) => {
      state.shared_list = payload;
      state.shared_status = "success";
    },
    [getSharedPastes.rejected]: (state, action) => {
      state.shared_status = "failed";
    },
  },
});

export default pasteSlice.reducer;
