import { useAccount, useMsal } from "@azure/msal-react";
import { protectedResources } from "authConfig";
import { getPasteDate } from "pastebin/pasteTimestamp";
import {
  baseDiv,
  headerDiv,
  linkSpan,
  refreshButton,
  tableStyling,
  titleFont,
} from "pastebin/Styles";
import { getSharedPastes } from "store/pasteSlice";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuthorizationToken } from "utils/AuthorizedFetchCalls";
import { useEffect } from "react";
import { DefaultButton } from "@fluentui/react";
import PaginationTable from "paginationTable/Table";

const SharedPastes = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  // grab global states from redux store
  const sharedPastes = useSelector((state) => state.paste.shared_list).map(
    (paste) => {
      return {
        ...paste,
        shortenedName: paste.pasteName.substring(
          paste.pasteName.indexOf("-") + 1
        ),
        pasteUrl: paste.pasteName.substring(0, paste.pasteName.indexOf("-")),
        created: getPasteDate(paste.pasteTimeStamp),
      };
    }
  );
  const getPastesStatus = useSelector((state) => state.paste.shared_status);
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  // Column Settings for the Table
  const columns = [
    {
      key: "column1",
      name: "Name",
      fieldName: "shortenedName",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      onRender: (item) => {
        return (
          <div
            className={linkSpan}
            onClick={() => navigate(`/pastes/${item.pasteUrl}`)}
          >
            {item.shortenedName}
          </div>
        );
      },
    },
    {
      key: "column2",
      name: "Owner",
      fieldName: "owner",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Created",
      fieldName: "created",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  /**
   * Function to dispatch the redux action to retrieve the fetching of Shared Pastes List
   */
  const getPasteList = useCallback(async () => {
    if (selectedCloud == null) return;

    const accessToken = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    dispatch(getSharedPastes(accessToken));
  }, [account, instance, dispatch, selectedCloud]);

  /**
   * On start, retrieve paste list
   */
  useEffect(() => {
    getPasteList();
  }, [getPasteList]);

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>Pastes Shared With Me</span>
        <DefaultButton
          text="Refresh"
          onClick={() => getPasteList()}
          className={refreshButton}
        />
      </div>
      <PaginationTable
        items={sharedPastes}
        columns={columns}
        tableStyling={tableStyling}
        loading={getPastesStatus === "loading" || selectedCloud === null}
      />
    </div>
  );
};

export default SharedPastes;
