import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { callAuthorizedEndpoint } from "utils/AuthorizedFetchCalls";
import { batchesAPI } from "utils/endpoints";

export const getBatches = createAsyncThunk(
  "batch/getBatches",
  async (token, getThunkAPI) => {
    return callAuthorizedEndpoint(`${batchesAPI}`, token)
      .then((res) => res.json())
      .then((obj) => {
        const batchList = obj.batches;
        return batchList;
      });
  }
);

export const batchSlice = createSlice({
  name: "batch",
  initialState: {
    list: [],
    status: null,
    approvals: [],
    approvals_status: null,
  },
  extraReducers: {
    [getBatches.pending]: (state, action) => {
      state.status = "loading";
    },
    [getBatches.fulfilled]: (state, { payload }) => {
      state.list = payload;
      state.status = "success";
    },
    [getBatches.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default batchSlice.reducer;
