const MOCK_FRONTEND_CLIENT_ID = "00000000-0000-0000-0000-000000000000";
const DEV_FRONTEND_CLIENT_ID = "762f8c63-6311-4c92-9229-2831601c2f4d";
const USSEC_FRONTEND_CLIENT_ID = "1d03f1df-bdcb-4490-a77b-1204a99b58e4";
const USNAT_FRONTEND_CLIENT_ID = "8c04bc53-7055-4edd-9747-a00659f36715";
const FRONTEND_CLIENT_ID =
  process.env.NODE_ENV === "test"
    ? MOCK_FRONTEND_CLIENT_ID
    : process.env.NODE_ENV === "development"
    ? DEV_FRONTEND_CLIENT_ID
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BUILD_MODE === "ussec"
      ? USSEC_FRONTEND_CLIENT_ID
      : process.env.REACT_APP_BUILD_MODE === "usnat"
      ? USNAT_FRONTEND_CLIENT_ID
      : process.env.REACT_APP_BUILD_MODE === "ppe-recv"
      ? DEV_FRONTEND_CLIENT_ID
      : DEV_FRONTEND_CLIENT_ID
    : MOCK_FRONTEND_CLIENT_ID;

const MOCK_AUTHORITY =
  "https://login.microsoftonline.test/00000000-0000-0000-0000-000000000000";
const DEV_AUTHORITY =
  "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47";
const USSEC_AUTHORITY =
  "https://login.microsoftonline.microsoft.scloud/20ac2fc4-effc-4f76-ba7d-63c5ae134f5f";
const USNAT_AUTHORITY =
  "https://login.microsoftonline.eaglex.ic.gov/70a90262-f46c-48aa-ac4c-37e37f8be1a2";
const AUTHORITY =
  process.env.NODE_ENV === "test"
    ? MOCK_AUTHORITY
    : process.env.NODE_ENV === "development"
    ? DEV_AUTHORITY
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BUILD_MODE === "ussec"
      ? USSEC_AUTHORITY
      : process.env.REACT_APP_BUILD_MODE === "usnat"
      ? USNAT_AUTHORITY
      : process.env.REACT_APP_BUILD_MODE === "ppe-recv"
      ? DEV_AUTHORITY
      : DEV_AUTHORITY
    : MOCK_AUTHORITY;

export const msalConfig = {
  auth: {
    clientId: FRONTEND_CLIENT_ID,
    authority: AUTHORITY,
    redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const graphApiScopes = ["User.Read"];

const MOCK_BACKENDAPI = "api://00000000-0000-0000-0000-000000000000/Portal.Api";
const DEV_BACKENDAPI = "api://c63a96e8-31c9-4b03-b3fe-4bd9b484eb71/portal-api";
const USSEC_BACKENDAPI =
  "api://34ef55f2-4410-42b3-9a9f-9de12fe1b5ee/Portal.Api";
const USNAT_BACKENDAPI =
  "api://1c956473-d04c-4843-b7ce-6d34af931b63/Portal.Api";

const BACKENDAPI =
  process.env.NODE_ENV === "test"
    ? MOCK_BACKENDAPI
    : process.env.NODE_ENV === "development"
    ? DEV_BACKENDAPI
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BUILD_MODE === "ussec"
      ? USSEC_BACKENDAPI
      : process.env.REACT_APP_BUILD_MODE === "usnat"
      ? USNAT_BACKENDAPI
      : process.env.REACT_APP_BUILD_MODE === "ppe-recv"
      ? DEV_BACKENDAPI
      : DEV_BACKENDAPI
    : MOCK_BACKENDAPI;

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  backendApi: {
    scopes: [BACKENDAPI], // e.g. api://xxxxxx/access_as_user
  },
};
