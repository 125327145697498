import { useAccount, useMsal } from "@azure/msal-react";
import { protectedResources } from "authConfig";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import {
  callAuthorizedEndpoint,
  getAuthorizationToken,
} from "utils/AuthorizedFetchCalls";
import { adminEgressAPI } from "utils/endpoints";
import LoadingView from "../LoadingView";
import {
  baseDiv,
  errorTextStyle,
  headerDiv,
  pivotPadding,
  titleFont,
} from "../Styles";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import FlowDetails from "./FlowDetails";

const AdminEgressFlowDetails = () => {
  const { flowGuid } = useParams();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isLoading, setIsLoading] = useState(true);
  const [errorText, setErrorText] = useState(null);
  const [flow, setFlow] = useState(null);

  const cachedFlowInfo = useSelector(
    (state) => state.egressFlows.admin_list
  ).find((flow) => flow.dataflowGuid === flowGuid);
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  const getFlow = useCallback(async () => {
    if (selectedCloud === null) return;

    const token = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );
    callAuthorizedEndpoint(`${adminEgressAPI}/${flowGuid}`, token)
      .then((response) => response.json())
      .then((response) => {
        setFlow(response.flow);
        setIsLoading(false);
      })
      .catch((response) =>
        response.text().then((text) => {
          setErrorText(text);
          setIsLoading(false);
        })
      );
  }, [account, instance, flowGuid, selectedCloud]);

  useEffect(() => {
    if (cachedFlowInfo) {
      setFlow(cachedFlowInfo);
      setIsLoading(false);
    } else {
      getFlow();
    }
  }, [cachedFlowInfo, getFlow]);

  const content = flow ? (
    <>
      <div className={headerDiv}>
        <span className={titleFont}>{flow.title}</span>
      </div>
      <div className={pivotPadding}>
        <FlowDetails flow={flow} />
      </div>
    </>
  ) : (
    <div className={errorTextStyle}>{errorText}</div>
  );

  return (
    <div className={baseDiv}>
      {isLoading ? <LoadingView label="Fetching Flow details" /> : content}
    </div>
  );
};

export default AdminEgressFlowDetails;
