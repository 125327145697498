import { useAccount, useMsal } from "@azure/msal-react";
import { DefaultButton } from "@fluentui/react";
import { protectedResources } from "authConfig";
import * as React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { flowStates } from "egressFlows/customer/EgressFlows";
import { getAdminFlows } from "store/egressFlowSlice";
import { getAuthorizationToken } from "utils/AuthorizedFetchCalls";
import {
  baseDiv,
  headerDiv,
  linkSpan,
  refreshButton,
  tableStyling,
  titleFont,
} from "egressFlows/Styles";
import PaginationTable from "paginationTable/Table";

export const AdminEgressFlows = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();

  // grab global states from redux store
  const flows = useSelector((state) => state.egressFlows.admin_list);
  const getFlowsStatus = useSelector(
    (state) => state.egressFlows.admin_list_status
  );
  const selectedCloud = useSelector((state) => state.tentedClouds.selected);

  const columns = [
    {
      key: "column1",
      name: "Dataflow GUID",
      minWidth: 250,
      maxWidth: 250,
      onRender: (item) => {
        return (
          <div
            className={linkSpan}
            onClick={() => navigate(`/egress/${item.dataflowGuid}`)}
          >
            {item.dataflowGuid}
          </div>
        );
      },
      isResizable: true,
    },
    {
      key: "column2",
      name: "Status",
      fieldName: "egressStatus",
      onRender: (item) => {
        return <div>{flowStates[item.egressStatus]}</div>;
      },
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Title",
      fieldName: "title",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Owner",
      fieldName: "primaryContact",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "column5",
      name: "Storage Account Name",
      fieldName: "storageAccountName",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: "column6",
      name: "Storage Container Name",
      fieldName: "storageContainerName",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
    },
  ];

  /**
   * Function to dispatch the redux action to retrieve the fetching of the list of Ingress Flows
   */
  const getFlowList = useCallback(async () => {
    if (selectedCloud == null) return;

    const accessToken = await getAuthorizationToken(
      instance,
      protectedResources.backendApi.scopes,
      account
    );

    dispatch(getAdminFlows(accessToken));
  }, [account, instance, dispatch, selectedCloud]);

  /**
   * On start, retrieve list of flows or when cloud mode got toggled
   */
  useEffect(() => {
    getFlowList();
  }, [getFlowList]);

  return (
    <div className={baseDiv}>
      <div className={headerDiv}>
        <span className={titleFont}>All Egress Flows</span>
        <DefaultButton
          text="Refresh"
          onClick={() => getFlowList()}
          className={refreshButton}
        />
      </div>
      <PaginationTable
        items={flows}
        columns={columns}
        tableStyling={tableStyling}
        loading={getFlowsStatus === "loading" || selectedCloud === null}
      />
    </div>
  );
};
export default AdminEgressFlows;
