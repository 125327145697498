import { fileTableDiv } from "../Styles";
import {
  DetailsList,
  CheckboxVisibility,
} from "@fluentui/react/lib/DetailsList";

/* Function to convert a full directory path to just the file name */
export const pathToFileName = (path) => {
  return path.split("\\").pop().split("/").pop();
};

/**
 * Generates a URI with the SAS token included to be able to modify Azure Storage Blobs through HTTP Requests.
 * @param {string} containerSAS the URI for the Azure Storage Blob container with the SAS appended as the query parameter
 * @param {string} blobFileName the name of the blob
 */
export const generateBlobFileUri = (containerSAS, blobFileName) => {
  const containerSplit = containerSAS.split("?");

  return `${containerSplit[0]}/${blobFileName}?${containerSplit[1]}`;
};

/**
 * Given a file size in bytes, convert to a more human friendly string
 * @param {int} bytes file size
 */
export const calculateFileSize = (bytes) => {
  const BYTES_PER_KB = 1024;
  const BYTES_PER_MB = BYTES_PER_KB * 1024;
  const BYTES_PER_GB = BYTES_PER_MB * 1024;

  if (Math.floor(bytes / BYTES_PER_KB) === 0) {
    return `${bytes} B`;
  } else if (Math.floor(bytes / BYTES_PER_MB) === 0) {
    return `${Math.floor(bytes / BYTES_PER_KB)} KB`;
  } else if (Math.floor(bytes / BYTES_PER_GB) === 0) {
    return `${Math.floor(bytes / BYTES_PER_MB)} MB`;
  } else {
    return `${Math.floor(bytes / BYTES_PER_GB)} GB`;
  }
};

const BatchFilesPivot = (props) => {
  const { batch } = props;
  const blobs = batch ? batch.blobs : [];

  // Column Settings for the Table which lists the files in the Batch
  const columns = [
    {
      key: "column1",
      name: "File Name",
      fieldName: "name",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      onRender: (item) => {
        return <div>{pathToFileName(item.name)}</div>;
      },
    },
    {
      key: "column2",
      name: "Size",
      fieldName: "size",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item) => {
        return <div>{calculateFileSize(item.properties.contentLength)}</div>;
      },
    },
    {
      key: "column3",
      name: "Content Type",
      fieldName: "contentType",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item) => {
        return <div>{item.properties.contentType}</div>;
      },
    },
  ];

  return (
    <div className={fileTableDiv}>
      <DetailsList
        compact={true}
        items={blobs}
        columns={columns}
        checkboxVisibility={CheckboxVisibility.hidden}
      />
    </div>
  );
};

export default BatchFilesPivot;
