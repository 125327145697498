import { mergeStyles } from "@fluentui/merge-styles";
var classNames = require("classnames");

const navigationContainer = mergeStyles({
  display: "flex",
});

const flexColumn = mergeStyles({
  display: "flex",
  flexDirection: "column",
});

const flexCenter = mergeStyles({
  alignItems: "center",
});

const scrollHidden = mergeStyles({
  overflow: "hidden",
});

const relative = mergeStyles({
  position: "relative",
});

const projectNavigation = mergeStyles({
  background: "rgb(234, 234, 234)",
  borderColor: "rgb(234, 234, 234)",
  borderRight: "1px solid rgba(0, 0, 0, 0.1)",
  color: "rgba(0, 0, 0, 0.9)",
  whiteSpace: "nowrap",
});

const regionNavigation = mergeStyles({
  overflow: "hidden",
  position: "relative",
  width: "48px",
  transition: "width 1ms cubic-bezier(.4,0,.2,1)",
});

const flexGrow = mergeStyles({
  flexGrow: "1",
});

const separator = mergeStyles({
  [`.${projectNavigation} &`]: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    height: "1px",
    margin: "0 10px",
  },
});

const flexRow = mergeStyles({
  display: "flex",
  flexDirection: "row",
});

const flexNoShrink = mergeStyles({
  flexShrink: "0",
});

export const expanded = mergeStyles({
  [`.${regionNavigation}&`]: {
    "transition-duration": "200ms",
    width: "260px",
  },
});

const collapsibleSection = mergeStyles({
  [`.${projectNavigation}.${expanded} &`]: {
    display: "flex",
    flexDirection: "row",
  },
});

const hub = mergeStyles({
  display: "flex",
});

const privacyText = mergeStyles({
  paddingLeft: "0.75rem",
});

const standAlone = mergeStyles({
  [`.${hub}&`]: {
    paddingBottom: "4px",
    paddingTop: "4px",
  },
});

export const collapsed = mergeStyles({
  display: "flex",
});

const expandedOnly = mergeStyles({
  [`.${projectNavigation}.${collapsed} &`]: {
    display: "none",
  },
});

const navigationLink = mergeStyles({
  [`.${regionNavigation} &`]: {
    color: "rgba(0, 0, 0, 0.9)",
    paddingRight: "4px",
  },
});

const textEllipsis = mergeStyles({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

export const displayed = mergeStyles({
  [`.${hub}&`]: {
    backgroundColor: "rgba(0, 0, 0, 0.06)",
    selectors: {
      ":before": {
        bottom: "0",
        backgroundColor: "rgb(0, 120, 212)",
        content: "''",
        left: "0",
        position: "absolute",
        top: "0",
        width: "3px",
      },
    },
  },
});

const navigationText = mergeStyles({
  [`.${displayed} &`]: {
    fontWeight: "600",
  },
});

const altHoverEffect = mergeStyles({
  selectors: {
    ":hover": {
      color: "rgba(0, 90, 158, 1)",
      textShadow: "0 0 4px rgba(0, 120, 212, 0.5)",
    },
  },
});

const fabricIcon = mergeStyles({
  alignSelf: "center",
  display: "inline-flex !important",
});

const navigationIcon = mergeStyles({
  [`.${projectNavigation} &`]: {
    paddingBottom: "12px",
    paddingTop: "12px",
    width: "47px",
  },
});

const justifyCenter = mergeStyles({
  justifyContent: "center",
});

const cursorPointer = mergeStyles({
  cursor: "pointer",
});

const medium = mergeStyles({
  fontSize: "1rem",
  lineHeight: "1rem",
});

export const projectItem = mergeStyles({
  display: "flex",
});

const personaIcon = mergeStyles({
  paddingLeft: "12px",
});

const fontWeightHeavy = mergeStyles({
  fontWeight: "bold",
});

export const dropdownIconStyle = mergeStyles({
  paddingBottom: "12px",
  paddingTop: "12px",
  width: "47px",
  fontSize: "1rem",
  lineHeight: "1rem",
  alignSelf: "center",
  display: "inline-flex !important",
  justifyContent: "center",
});

export const cloudToggleSelection = mergeStyles({
  height: "48px",
  width: "150px",
  display: "flex",
  selectors: {
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.06)",
      cursor: "pointer",
    },
  },
});

const navigationSection = mergeStyles({
  [`.${projectNavigation} &`]: {
    margin: "4px 0",
  },
});

const vScrollAuto = mergeStyles({
  overflowY: "auto",
});

const hubGroupContainer = mergeStyles({
  display: "flex",
});

const expandedContainer = mergeStyles({
  [`.${hubGroupContainer}&`]: {
    backgroundColor: "rgba(0, 0, 0, 0.06)",
  },
});

const displayedContainer = mergeStyles({
  [`.${hubGroupContainer}&`]: {
    selectors: {
      ":before": {
        bottom: "0",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        content: "''",
        left: "0",
        position: "absolute",
        top: "0",
        width: "3px",
      },
    },
  },
});

const navigationElement = mergeStyles({
  selectors: {
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.06)",
    },
  },
});

const boltLink = mergeStyles({
  borderRadius: "2px",
  cursor: "pointer",
  textDecoration: "none",
  transition: "color 80ms cubic-bezier(.165,.84,.44,1),background 80ms linear",
});

const rootElement = mergeStyles({
  paddingBottom: "6px !important",
  paddingTop: "6px !important",
});

const contributedIconImage = mergeStyles({
  fontSize: "1.5rem",
  backgroundSize: "contain",
});

const hubGroupOnly = mergeStyles({
  selectors: {
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.06)",
    },
  },
  display: "flex",
});

export const sideNavBase = classNames(navigationContainer, flexColumn);
export const sideNavDiv = classNames(
  projectNavigation,
  regionNavigation,
  flexColumn,
  flexGrow
);
export const separatorLine = classNames(separator, flexRow, flexNoShrink);
export const hubGroupBase = classNames(collapsibleSection, flexNoShrink);
export const privacyTextDiv = classNames(
  hub,
  privacyText,
  standAlone,
  expandedOnly,
  navigationLink,
  flexRow,
  flexGrow,
  flexCenter,
  scrollHidden,
  relative
);
export const privacyTextStyle = classNames(
  navigationText,
  expandedOnly,
  textEllipsis,
  flexGrow
);
export const arrowButtonBase = classNames(
  hub,
  standAlone,
  altHoverEffect,
  flexRow
);
export const arrowButtonDiv = classNames(
  navigationIcon,
  flexRow,
  flexCenter,
  flexNoShrink,
  justifyCenter,
  cursorPointer,
  fabricIcon
);
export const arrowIconStyle = classNames(fabricIcon, medium);

export const cloudToggleBase = classNames(collapsibleSection, flexNoShrink);
export const cloudToggleDiv = classNames(
  projectItem,
  flexRow,
  flexGrow,
  flexCenter,
  scrollHidden
);
export const cloudToggleIcon = classNames(
  navigationIcon,
  personaIcon,
  flexRow,
  flexCenter,
  flexNoShrink,
  justifyCenter
);
export const cloudToggleText = classNames(
  fontWeightHeavy,
  expandedOnly,
  flexGrow,
  textEllipsis
);
export const cloudToggleArrow = classNames(
  altHoverEffect,
  flexRow,
  cursorPointer,
  flexCenter
);
export const cloudToggleSelectionBox = classNames(
  projectItem,
  flexRow,
  flexGrow,
  flexCenter,
  scrollHidden
);
export const cloudToggleSelectionIcon = classNames(
  navigationIcon,
  personaIcon,
  flexRow,
  flexCenter,
  flexNoShrink,
  justifyCenter,
  flexNoShrink
);
export const cloudToggleSelectionText = classNames(
  fontWeightHeavy,
  expandedOnly,
  flexGrow,
  textEllipsis
);

export const navSelectionStyle = classNames(
  navigationSection,
  flexColumn,
  flexGrow,
  scrollHidden,
  vScrollAuto
);
export const navSelectionExpandedDiv = classNames(
  hubGroupContainer,
  flexColumn,
  flexNoShrink,
  relative,
  expandedContainer,
  displayedContainer
);
export const navSelectionExpandedRootDiv = classNames(
  navigationElement,
  displayed,
  navigationLink,
  flexRow,
  flexGrow,
  flexCenter,
  scrollHidden,
  relative,
  boltLink
);
export const navSelectionExpandedRootIconDiv = classNames(
  rootElement,
  navigationIcon,
  flexRow,
  flexCenter,
  flexNoShrink,
  justifyCenter
);
export const navSelectionExpandedRootIcon = classNames(
  contributedIconImage,
  flexNoShrink
);
export const navSelectionExpandedRootText = classNames(
  navigationText,
  expandedOnly,
  textEllipsis,
  flexGrow
);
export const navSelectionExpandedChildDiv = classNames(
  navigationElement,
  hub,
  navigationLink,
  flexRow,
  flexGrow,
  flexCenter,
  scrollHidden,
  relative,
  boltLink
);
export const navSelectionExpandedChildIconDiv = classNames(
  navigationIcon,
  flexRow,
  flexCenter,
  flexNoShrink,
  justifyCenter
);
export const navSelectionExpandedChildIcon = classNames(
  flexNoShrink,
  fabricIcon,
  medium
);
export const navSelectionExpandedChildText = classNames(
  navigationText,
  expandedOnly,
  textEllipsis,
  flexGrow
);

export const navSelectionCondensedDiv = classNames(
  hubGroupContainer,
  flexColumn,
  flexNoShrink,
  relative,
  hubGroupOnly
);
export const navSelectionCondensedRootDiv = classNames(
  navigationElement,
  navigationLink,
  flexRow,
  flexGrow,
  flexCenter,
  scrollHidden,
  relative,
  boltLink
);
export const navSelectionCondensedRootIconDiv = classNames(
  rootElement,
  navigationIcon,
  flexRow,
  flexCenter,
  flexNoShrink,
  justifyCenter
);
export const navSelectionCondensedRootIcon = classNames(
  contributedIconImage,
  flexNoShrink
);
export const navSelectionCondensedRootText = classNames(
  navigationText,
  expandedOnly,
  textEllipsis,
  flexGrow
);
