import { Icon } from "@fluentui/react";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { CUSTOMER_MODE } from "store/modeSlice";
import CloudToggle from "./CloudToggle";
import NavSelection from "./customer/NavSelection";
import AdminNavSelection from "./admin/NavSelection";
import {
  collapsed,
  hubGroupBase,
  expanded,
  separatorLine,
  sideNavBase,
  sideNavDiv,
  privacyTextDiv,
  arrowButtonBase,
  arrowButtonDiv,
  arrowIconStyle,
  privacyTextStyle,
} from "./Styles";

const SideNavBar = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const widthClass = isExpanded ? expanded : collapsed;
  const arrowIcon = isExpanded ? "DoubleChevronLeft" : "DoubleChevronRight";

  const currentMode = useSelector((state) => state.mode.currentMode);
  const navSelection =
    currentMode === CUSTOMER_MODE ? <NavSelection /> : <AdminNavSelection />;

  return (
    <div className={sideNavBase}>
      <div className={`${sideNavDiv} ${widthClass}`}>
        <CloudToggle />
        <div className={separatorLine} />
        {navSelection}
        <div className={separatorLine} />

        <div className={hubGroupBase}>
          <div className={privacyTextDiv}>
            <span className={privacyTextStyle}>
              &copy; 2021 - Microsoft -{" "}
              <a
                target="_blank"
                href="https://privacy.microsoft.com/en-US/data-privacy-notice"
                rel="noreferrer"
              >
                Privacy
              </a>
            </span>
          </div>
          <div
            onClick={() => setIsExpanded(!isExpanded)}
            className={arrowButtonBase}
          >
            <span className={arrowButtonDiv}>
              <Icon
                iconName={arrowIcon}
                className={arrowIconStyle}
                data-testid="arrow-button"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNavBar;
