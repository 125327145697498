const api = "/api";

export const batchesAPI = `${api}/BatchesRecv`;

export const sharedBatchesAPI = `${api}/SharedBatchesRecv`;

export const pastesAPI = `${api}/pastesRecv`;
export const sharedPastesAPI = `${api}/sharedPastesRecv`;

export const authorizedAPI = `${api}/AuthorizedRecv`;
export const authorizedCloudsAPI = `${authorizedAPI}/clouds`;
export const authorizedRolesAPI = `${authorizedAPI}/roles`;
export const authorizedClustersAPI = `${authorizedAPI}/clusters`;

export const complianceAPI = `${api}/compliance`;
export const aupAPI = `${complianceAPI}/aup`;
export const userAupAPI = `${aupAPI}/user`;
export const ingressFlowAupAPI = `${aupAPI}/teleport`;
export const egressFlowAupAPI = `${aupAPI}/egress`;
export const batchApproverAupAPI = `${aupAPI}/approver`;

export const flowsAPI = `${api}/FlowsRecv`;
export const validateSasAPI = `${flowsAPI}/sas`;
export const ingressFlowAPI = `${flowsAPI}/ingress`;
export const egressFlowAPI = `${flowsAPI}/egress`;

export const analyticsAPI = `${api}/analytics`;

export const adminAPI = `${api}/admin`;
export const adminFlowsAPI = `${adminAPI}/flows`;
export const adminIngressAPI = `${adminFlowsAPI}/IngressRecv`;
export const adminEgressAPI = `${adminFlowsAPI}/egressRecv`;

export const notificationAPI = `${api}/notification`;

const MOCK_GRAPH_ENDPOINT = "https://graph.microsoft.test/";
const DEV_GRAPH_ENDPOINT = "https://graph.microsoft.com/";
const USSEC_GRAPH_ENDPOINT = "https://graph.microsoft.scloud/";
const USNAT_GRAPH_ENDPOINT = "https://graph.eaglex.ic.gov/";
export const GRAPH_ENDPOINT =
  process.env.NODE_ENV === "test"
    ? MOCK_GRAPH_ENDPOINT
    : process.env.NODE_ENV === "development"
    ? DEV_GRAPH_ENDPOINT
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BUILD_MODE === "ussec"
      ? USSEC_GRAPH_ENDPOINT
      : process.env.REACT_APP_BUILD_MODE === "usnat"
      ? USNAT_GRAPH_ENDPOINT
      : process.env.REACT_APP_BUILD_MODE === "ppe-recv"
      ? DEV_GRAPH_ENDPOINT
      : DEV_GRAPH_ENDPOINT
    : MOCK_GRAPH_ENDPOINT;

const MOCK_MY_ACCOUNT_LINK = "https://myaccount.microsoft.test/?ref=MeControl";
const DEV_MY_ACCOUNT_LINK = "https://myaccount.microsoft.com/?ref=MeControl";
const USSEC_MY_ACCOUNT_LINK =
  "https://myaccount.microsoft.scloud/?ref=MeControl";
const USNAT_MY_ACCOUNT_LINK = "https://myaccount.eaglex.ic.gov/?ref=MeControl";
export const MY_ACCOUNT_LINK =
  process.env.NODE_ENV === "test"
    ? MOCK_MY_ACCOUNT_LINK
    : process.env.NODE_ENV === "development"
    ? DEV_MY_ACCOUNT_LINK
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BUILD_MODE === "ussec"
      ? USSEC_MY_ACCOUNT_LINK
      : process.env.REACT_APP_BUILD_MODE === "usnat"
      ? USNAT_MY_ACCOUNT_LINK
      : process.env.REACT_APP_BUILD_MODE === "ppe-recv"
      ? DEV_MY_ACCOUNT_LINK
      : DEV_MY_ACCOUNT_LINK
    : MOCK_MY_ACCOUNT_LINK;
