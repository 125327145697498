import * as React from "react";
import { useSelector } from "react-redux";
import NavSelectionGroup from "../NavSelectionGroup";

const IngressNavGroup = () => {
  const roles = useSelector((state) => state.mode.roles);

  const links = [];

  if (roles.dri) {
    links.push(
      {
        title: "All ingress flows",
        icon: "CloudSearch",
        path: "/ingress",
      },
      {
        title: "Convert flow access",
        icon: "Switch",
        path: "/convertflowaccess",
      }
    );
  }

  const rootProps = {
    title: "Ingress Flows",
    icon: "CloudUpload",
    path: links.length > 0 ? links[0].path : null,
  };

  return links.length > 0 ? (
    <NavSelectionGroup root={rootProps} links={links} />
  ) : null;
};

export default IngressNavGroup;
